import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { v4 as uuid } from "uuid";
import { useHMSActions } from "@100mslive/react-sdk";
import { Box, Flex, Loading, styled } from "@100mslive/roomkit-react";
import PreviewContainer from "./Preview/PreviewContainer";
import SidePane from "../layouts/SidePane";
import { ErrorDialog } from "../primitives/DialogContent";
import { Header } from "./Header";
import { useSetUiSettings, useTokenEndpoint } from "./AppData/useUISettings";
import { useNavigation } from "./hooks/useNavigation";
import getToken from "../services/tokenService";
import {
  QUERY_PARAM_AUTH_TOKEN,
  QUERY_PARAM_NAME, 
  QUERY_PARAM_PREVIEW_AS_ROLE,
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  UI_SETTINGS,
} from "../common/constants";
import { useSelector } from "react-redux";

/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */





const manual_token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXJzaW9uIjoyLCJ0eXBlIjoiYXBwIiwiYXBwX2RhdGEiOm51bGwsImFjY2Vzc19rZXkiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODciLCJyb2xlIjoiYnJvYWRjYXN0ZXIiLCJyb29tX2lkIjoiNjY3YzU1OWM3MGU1YTdjZGFlYTNiZDI5IiwidXNlcl9pZCI6ImQ0M2IxZTY0LWEzNWYtNDEzZS04ZGI3LWY2ZTU2ZDY2NWQ2MSIsImV4cCI6MTcyMDAzMjQxNiwianRpIjoiZWU0NmUxNzEtMTNjMS00MDliLTgyZjEtOGFiZmRkOWE4MjVjIiwiaWF0IjoxNzE5OTQ2MDE2LCJpc3MiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODUiLCJuYmYiOjE3MTk5NDYwMTYsInN1YiI6ImFwaSJ9.rIpaZvccD50jhhE88Qp92whrpfcCWA46VTXegsHvZy4";

const env = process.env.REACT_APP_ENV;
const PreviewScreen = React.memo(({ authTokenByRoomCodeEndpoint }) => {
  const navigate = useNavigation();
  const hmsActions = useHMSActions();
  const tokenEndpoint = useTokenEndpoint();
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
  const { roomId: urlRoomId, role: userRole } = useParams(); // from the url
  const [token, setToken] = useState(null);

  const [error, setError] = useState({ title: "", body: "" });
  // way to skip preview for automated tests, beam recording and streaming
  const beamInToken = useSearchParam("token") === "beam_recording"; // old format to remove
  let skipPreview = useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true";
  // use this field to join directly for quick testing while in local
  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const directJoinHeadful =
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
    directJoinHeadfulFromEnv;
  skipPreview = skipPreview || beamInToken || directJoinHeadful;
  const initialName =
    useSearchParam(QUERY_PARAM_NAME) || (skipPreview ? "Beam" : "");
  const previewAsRole = useSearchParam(QUERY_PARAM_PREVIEW_AS_ROLE);
  let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);
  const roomId = useSelector((state) => state.roomCode.roomId);
  const role = useSelector((state) => state.roomCode.role);


console.log("roomId from selector: ", roomId);
console.log("role from selector: ", role);

  useEffect(() => {
    if (!roomId || !role) {
      setError('Room ID or role is missing from the state.');
      return;
    }
    (async () => {
      try {
        console.log("fetching token against ");
        console.log("roomID:: ",roomId);
        console.log("role ",role);


        const response = await fetch('https://prod-in2.100ms.live/hmsapi/muhammad-livestream-1657.app.100ms.live/api/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            room_id: roomId,
            role: role,
            user_id: '1234',
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("token response:",data);
        setToken(data.token);
      } catch (error) {
        setError(error.message);
      }
    })();




















    // if (authToken) {
    //   setToken(authToken);
    //   return;
    // }
    // if (!tokenEndpoint || !urlRoomId) {
    //   return;
    // }
    // const roomCode = !userRole && urlRoomId;

    // const getTokenFn = roomCode
    //   ? () =>
    //       hmsActions.getAuthTokenByRoomCode(
    //         { roomCode },
    //         { endpoint: authTokenByRoomCodeEndpoint }
    //       )
    //   : () => getToken(tokenEndpoint, uuid(), userRole, urlRoomId);

    // getTokenFn()
    //   .then(token => {
    //     setToken(token);
    //   })
    //   .catch(error => {
    //     setError(convertPreviewError(error));
    //   });
  }, [
    hmsActions,
    tokenEndpoint,
    urlRoomId,
    userRole,
    authToken,
    authTokenByRoomCodeEndpoint,
  ]);

  const onJoin = () => {
    !directJoinHeadful && setIsHeadless(skipPreview);
    let meetingURL = `/meeting/${urlRoomId}`;
    if (userRole) {
      meetingURL += `/${userRole}`;
    }
    navigate(meetingURL);
  };

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }
  return (
    <Flex direction="column" css={{ size: "100%" }}>
       <div className="course-header">
                        <div className="container">
                            <h3>Live Streaming</h3>
                        </div>
                    </div>
                            
                              <Box
        css={{ h: "$18", "@md": { h: "$17", flexShrink: 0 } }}
        data-testid="header"
      >
        <Header isPreview={true} />
      </Box>
      <Flex
        css={{ flex: "1 1 0", position: "relative", overflowY: "auto" }}
        justify="center"
        align="center"
      >
        {token ? (
          <>
            <PreviewContainer
              initialName={initialName}
              skipPreview={skipPreview}
              env={env}
              onJoin={onJoin}
              token={token}
              asRole={previewAsRole}
            />
          </>
        ) : (
          <Loading size={100} />
        )}
        <SidePane
          css={{
            position: "unset",
            mr: "$10",
            "@lg": { position: "fixed", mr: "$0" },
          }}
        />
      </Flex>
    </Flex>
  );
});

const convertPreviewError = error => {
  console.error("[error]", { error });
  if (error.action === "GET_TOKEN" && error.code === 403) {
    return {
      title: "Room code is disabled",
      body: ErrorWithSupportLink(
        "Room code corresponding to this link is no more active."
      ),
    };
  } else if (error.action === "GET_TOKEN" && error.code === 404) {
    return {
      title: "Room code does not exist",
      body: ErrorWithSupportLink(
        "We could not find a room code corresponding to this link."
      ),
    };
  } else if (error.action === "GET_TOKEN" && error.code === 2003) {
    return {
      title: "Endpoint is not reachable",
      body: ErrorWithSupportLink(
        `Endpoint is not reachable. ${error.description}.`
      ),
    };
  } else if (error.response && error.response.status === 404) {
    return {
      title: "Room does not exist",
      body: ErrorWithSupportLink(
        "We could not find a room corresponding to this link."
      ),
    };
  } else if (error.response && error.response.status === 403) {
    return {
      title: "Accessing room using this link format is disabled",
      body: ErrorWithSupportLink(
        "You can re-enable this from the developer section in Dashboard."
      ),
    };
  } else {
    console.error("Token API Error", error);
    return {
      title: "Error fetching token",
      body: ErrorWithSupportLink(
        "An error occurred while fetching the app token. Please look into logs for more details."
      ),
    };
  }
};

const Link = styled("a", {
  color: "#2f80e1",
});

export const ErrorWithSupportLink = errorMessage => (
  <div>
    {errorMessage} If you think this is a mistake on our side, please create{" "}
    <Link
      target="_blank"
      href="https://github.com/100mslive/100ms-web/issues"
      rel="noreferrer"
    >
      an issue
    </Link>{" "}
    or reach out over{" "}
    <Link
      target="_blank"
      href="https://discord.com/invite/kGdmszyzq2"
      rel="noreferrer"
    >
      Discord
    </Link>
    .
  </div>
);

export default PreviewScreen;
