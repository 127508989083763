import React, { useState } from "react";
import "./login.css";
import { auth, firestore } from "./firebase"; // Assuming you have initialized firestore
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg
} from "reactstrap";
import { addDoc, collection } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createUserWithEmailAndPassword } from "firebase/auth";

function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const [savingData , setSavingdata] = useState(false);

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!email || !password || !confirmPassword) {
            displayError("Please fill in all fields");
            return;
        }
        if (password !== confirmPassword) {
            displayError("Passwords do not match");
            return;
        }
    
        if (password.length < 8) {
            displayError("Password must be at least 8 characters long");
            return;
        }
    
        try {
            setSavingdata(true);
            // Create user with email and password using Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            
            // Save additional user data to Firestore
            const adminCollectionRef = collection(firestore, "admin");
            await addDoc(adminCollectionRef, {
                uid: userCredential.user.uid,
                email: email,
                password:password
                // Add additional data fields as needed
            });
            
            setSavingdata(false);
            navigate('/'); // Redirect to dashboard after signup
        } catch (error) {
            displayError(error.message);
            setSavingdata(false);
        }
    };

    const displayError = (errorMessage) => {
        toast.error(errorMessage);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return ( 
       <>
                {savingData && <Loader />}
       <ToastContainer position="top-right"/>
        <div className="background">
            <div className="login-box">
                <div className="container">
                    <div className="row app-des">
                        <div className="col left-background ">
                            <h2>Lectura</h2>
                            <CardImg className="mobile-img" src="logo.svg" alt="mobile-App" />
                        </div>
                        <div className="col login-form">
                            <form onSubmit={handleSignUp}>
                                <h2 className="font-weight-bold mb-4">Sign Up</h2>
                                <FormGroup>
                                    <Label className="font-weight-bold mb-2">Email</Label>
                                    <Input
                                        className="mb-3"
                                        type="email"
                                        placeholder="John@example.com"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Label className="font-weight-bold mb-2">Password</Label>
                                   
                                    <Input
                                        className="mb-3"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="At least 8 characters"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                     <div className="checkbox-wrapper-33">
                                        <label className="checkbox">
                                            <input
                                                className="checkbox__trigger visuallyhidden"
                                                type="checkbox"
                                                checked={showPassword}
                                                onChange={toggleShowPassword}
                                            />
                                            <span className="checkbox__symbol"style={{width:"20px",height:"20px"}}>
                                                <svg
                                                    aria-hidden="true"
                                                    className="icon-checkbox"
                                                    width="28px"
                                                    height="28px"
                                                    viewBox="0 0 28 28"
                                                    version="1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M4 14l8 7L24 7"></path>
                                                </svg>
                                            </span>
                                            <p className="checkbox__textwrapper"style={{fontSize:"12px"}}>Show Password</p>
                                        </label>
                                    </div>
                                    <Label className="font-weight-bold mb-2" >Confirm Password</Label>
                                   
                                    <Input
                                        className="mb-3"
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="At least 8 characters"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                     <div className="checkbox-wrapper-33">
                                        <label className="checkbox" >
                                            <input
                                            
                                                className="checkbox__trigger visuallyhidden"
                                                type="checkbox"
                                                checked={showConfirmPassword}
                                                onChange={toggleShowConfirmPassword}
                                                style={{width:"10px",height:"10px"}}
                                            />
                                            <span className="checkbox__symbol" style={{width:"20px",height:"20px"}}>
                                                <svg
                                                    aria-hidden="true"
                                                    className="icon-checkbox"
                                                    width="28px"
                                                    height="28px"
                                                    viewBox="0 0 28 28"
                                                    version="1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M4 14l8 7L24 7"></path>
                                                </svg>
                                            </span>
                                            <p className="checkbox__textwrapper" style={{fontSize:"12px"}}>Show Password</p>
                                        </label>
                                    </div>
                                </FormGroup>
                                <Button className="mt-3 btn" type="submit">
                                    Create User
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </>
    );
}

export default SignUp;
const Loader = () => {
    return (
      <div className="overlay">
 <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{'--index': index}} key={index}></div>
                    ))}
                </div>
            </div>
      </div>
    );
};
