import { useState } from "react";
import "./appbar.css"
import "./responsive.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Icon1 from "../Icons/Icon1";
import Icon2 from "../Icons/Icon2";
import Icon3 from "../Icons/Icon3";
import Icon4 from "../Icons/Icon4";
import Icon5 from "../Icons/Icon5";
import Icon6 from "../Icons/Icon6";
import Icon7 from "../Icons/Icon7";
import Icon8 from "../Icons/Icon8";
import Icon9 from "../Icons/Icon9";
import Icon10 from "../Icons/Icon10";
import Icon11 from "../Icons/Icon11";
import Icon12 from "../Icons/Icon12";
import Icon13 from "../Icons/Icon13";
import Icon15 from "../Icons/Icon15";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import UserIcon from "../Icons/Icon4";
import AddIcon from "../Icons/Icon2";
import EditIcon from "../Icons/Icon2";
import VerifyIcon from "../Icons/Icon3";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const [selectedOption, setSelectedOption] = useState(
    getInitialOption(location)
  );
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      setSelectedOption("Logout"); // Reset selectedOption to default
      navigate('/'); // Navigate to the login page
    } catch (error) {
      console.error('Error signing out:', error);
  } 
  };

  function getInitialOption(location) {
    const path = location.pathname;
    // Logic to map route paths to option names
    // For example:
    if (path === "/") {
      return "Option1";
    } else if (path === "/all-courses") {
      return "Option5";
    }
    
    else if (path === "/chat") {
      return "Option3";
      
    }
    else if (path === "/add-products") {
      return "Products";
      
    }
    else if (path === "/announcement") {
      return "Option9";
      
    }
    else if (path === "/vouchers") {
      return "Voucher";
      
    }
    else if (path === "/stage-price") {
      return "Stage";
      
    }
    else if (path === "/users") {
      return "users";
      
    }
    else if (path === "/edit-lessons") {
      return "edit";
      
    }
    else if (path === "/status") {
      return "status";
      
    }
    else if (path === "/verify") {
      return "verify";
      
    }
    else if (path === "/support") {
      return "support";
      
    }  else if (path === "/liveStreaming") {
      return "LiveStream";
      
    }
    else if (
      path === "/patientfiles" ||
      path === "/patientinfo" ||
      path === "/teeth-perminant"
    ) {
      return "Option6";
    } else if (
      path === "/inventory" ||
      path === "/inventory-product" ||
      path === "/inventory-detailed"
    ) {
      return "Option8";
    } else if (path === "/appointment") {
      return "Option9";
    }
    // Add more conditions for other routes as needed
    return "Option1"; // Default option
  }

  const handleOptionClick = (optionName) => {
    setSelectedOption(optionName);
  };




  const toggleSidebar = () => {
    setVisible(!visible);
  };


  return (

    
   <>
 <button className="burger-menu" onClick={toggleSidebar}>
        ☰
      </button> 


    <div className={`sidebar-container ${visible ? "visible" : ""}`}>
      <div className="cross-img-contained" onClick={toggleSidebar}>
        <Icon15 onClick={toggleSidebar} />
      </div>
     
      <div className="sidebar-content-set">
        <div className="sidebar-content-set-flex">
          {" "}



{/* //ADDING LIVE STREAM */}

            {/* <button onClick={
              ()=>{
                navigate("/meeting/665f6802a4c05af8cc3c9994/broadcaster")
              }
            }>Live Stream</button> */}



          <NavLink to="/dashboard" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "Option1" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "Option1" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("Option1")}
            >
              <div className="icon-set">
                <Icon1 selected={selectedOption === "Option1"} />
              </div>
              <div className="dashboard-contain"> Dashboard</div>
            </button>
          </NavLink>
          <NavLink to="/edit-lessons" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "edit" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "edit" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("edit")}
            >
              <div className="icon-set">
                <EditIcon selected={selectedOption === "edit"} />
              </div>
              <div className="dashboard-contain">Edit Lessons</div>
            </button>
          </NavLink>

         
         
          <NavLink to="/all-courses" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "Option5" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "Option5" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("Option5")}
            >
              <div className="icon-set">
                <Icon5 selected={selectedOption === "Option5"} />
              </div>
              <div className="dashboard-contain"> Add Courses</div>
            </button>
          </NavLink>
          <NavLink to="/chat" style={{ textDecoration: "none" }}>
            <button
               className="custom-button"
               style={{
                 color: selectedOption === "Option3" ? "white" : "#00000066",
                 backgroundColor:
                   selectedOption === "Option3" ? "#01919C9C" : "transparent",
               }}
               onClick={() => handleOptionClick("Option3")}
             >
               <div className="icon-set">
                 <Icon11 selected={selectedOption === "Option3"} />
              </div>
              <div className="dashboard-contain"> Chat</div>
            </button>
          </NavLink>
        
          <NavLink to="/add-products" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "Products" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "Products" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("Products")}
            >
              <div className="icon-set">
                <Icon7 selected={selectedOption === "Products"} />
              </div>
              <div className="dashboard-contain"> Add Products</div>
            </button>
          </NavLink>
          <NavLink to="/announcement" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "Option9" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "Option9" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("Option9")}
            >
              <div className="icon-set">
                <Icon9 selected={selectedOption === "Option9"} />
              </div>
              <div className="dashboard-contain">Add Announcements</div>
            </button>
          </NavLink>
          <NavLink to="/vouchers" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "Voucher" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "Voucher" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("Voucher")}
            >
              <div className="icon-set">
                <Icon8 selected={selectedOption === "Voucher"} />
              </div>
              <div className="dashboard-contain"> Vouchers</div>
            </button>
          </NavLink>
          <NavLink to="/stage-price" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "Stage" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "Stage" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("Stage")}
            >
              <div className="icon-set">
                <Icon6 selected={selectedOption === "Stage"} />
              </div>
              <div className="dashboard-contain"> Stage Price</div>
            </button>
          </NavLink>

          <NavLink to="/verify" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "verify" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "verify" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("verify")}
            >
              <div className="icon-set">
                <VerifyIcon selected={selectedOption === "verify"} />
              </div>
              <div className="dashboard-contain">Users Verification</div>
            </button>
          </NavLink>
          <NavLink to="/status" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "status" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "status" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("status")}
            >
              <div className="icon-set">
                <Icon10 selected={selectedOption === "status"} />
              </div>
              <div className="dashboard-contain">Order Status</div>
            </button>
          </NavLink>
          <NavLink to="/support" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "support" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "support" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("support")}
            >
              <div className="icon-set">
                <Icon12 selected={selectedOption === "support"} />
              </div>
              <div className="dashboard-contain">Support</div>
            </button>
          </NavLink>
          <NavLink to="/users" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "users" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "users" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("users")}
            >
              <div className="icon-set">
                <UserIcon selected={selectedOption === "users"} />
              </div>
              <div className="dashboard-contain">Users</div>
            </button>
          </NavLink>

          <NavLink to="/liveStreaming" style={{ textDecoration: "none" }}>
            <button
              className="custom-button"
              style={{
                color: selectedOption === "LiveStream" ? "white" : "#00000066",
                backgroundColor:
                  selectedOption === "LiveStream" ? "#01919C9C" : "transparent",
              }}
              onClick={() => handleOptionClick("LiveStream")}
            >
              <div className="icon-set">
                <Icon11 selected={selectedOption === "LiveStream"} />
              </div>
              <div className="dashboard-contain"> Live Stream</div>
            </button>
          </NavLink>
       
         
          <button
            className="custom-button"
            style={{
              color: selectedOption === "Logout" ? "white" : "#00000066",
              backgroundColor:
                selectedOption === "Logout" ? "#01919C9C" : "transparent",
            }}
            onClick={() => {handleOptionClick("Logout");handleLogout();}}
          >
            <div className="icon-set">
              <Icon13 selected={selectedOption === "Logout"} />
            </div>
            <div className="dashboard-contain"> Logout</div>
          </button>
        </div>
      </div>
    </div>
   </>
  );
};

export default Sidebar;
