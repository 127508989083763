import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchRoomCode, fetchRole, fetchRoomID  } from '../live0/redux/roomCodeSlice';

export default function TokenModal({ show, onHide, roomId }) {
  const dispatch = useDispatch();

  const [role, setRole] = useState('broadcaster'); // Default role set to 'broadcaster'
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();

  const handleJoin = async () => {

    try {
      console.log("room id sent for codes:  ",roomId);
      const response = await fetch(`https://api.100ms.live/v2/room-codes/room/${roomId}`, {
        headers: {
          Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTk0MjQzMzEsImV4cCI6MTcyMDYzMzkzMSwianRpIjoiMGUzNDc2ODItZmNkMy00ZGQ3LWIyYWUtNjY5MTZmMzViZTM3IiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3MTk0MjQzMzEsImFjY2Vzc19rZXkiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODcifQ.xcgZg1wLF5wL6oSbZkInoOcK2FioRUKv6MDlQZP--GI' 
          // Replace with your actual management token
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch room codes');
      }  
      const data = await response.json();
      console.log('Room Codes:', data.data); // Print room codes data
      // Find the room code for the specific role (replace 'host' with your actual role variable)
      console.log("role for matching: ", role);
      console.log("data from list: ", data);
      const matchingRoomCode = data.data.find(code => code.role === role && code.enabled);
      dispatch(fetchRole(role));
      dispatch(fetchRoomID(roomId));

      if (matchingRoomCode) {
        dispatch(fetchRoomCode(matchingRoomCode.code));
        console.log('Matching Room Code:', matchingRoomCode.code);
        // Redirect to meeting page with roomId and role
        navigate(`/meeting/${roomId}/${role}`);

      } else {
        console.error('No matching room code found for role:', role);
        // Handle case where no matching room code is found
      }
  
    } catch (error) {
      console.error('Error fetching room codes:', error);
      // Handle error as needed
    }
  };
  
  

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Get Auth Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formRoomId">
            <Form.Label>Room ID</Form.Label>
            <Form.Control type="text" value={roomId} readOnly />
          </Form.Group>
          <Form.Group controlId="formRole">
            <Form.Label>Select a role to join</Form.Label>
            <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="broadcaster">Broadcaster</option>
              <option value="co-broadcaster">Co-Broadcaster</option>
              <option value="viewer-realtime">Viewer - Realtime</option>
              <option value="viewer-near-realtime">Viewer - Near Realtime</option>
              <option value="viewer-on-stage">Viewer - On Stage</option>
            </Form.Control>
          </Form.Group>
          



          {/* <Form.Group controlId="formUserId">
            <Form.Label>User ID</Form.Label>
            <Form.Control type="text" placeholder="Enter User ID" value={userId} onChange={(e) => setUserId(e.target.value)} />
          </Form.Group> */}
          <br />
          <Button variant="primary" onClick={handleJoin}>
            Join now
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
