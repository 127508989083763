// roomCodeSlice.js
import { createSlice } from "@reduxjs/toolkit";
const roomCodeSlice = createSlice({
  name: "roomCode",
  initialState: {
    roomId: null,
    role: null,
    roomCode: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchRoomID(state, action) {
      state.roomId = action.payload;
    },
    fetchRole(state, action) {
      state.role = action.payload;
    },
    fetchRoomCode(state, action) {
      state.roomCode = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});
export const { fetchRoomID, fetchRole, fetchRoomCode, setLoading, setError } =
  roomCodeSlice.actions;

export default roomCodeSlice.reducer;
