import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { firestore, storage } from '../firebase';
import { useDropzone } from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragHandle, DragIndicator } from '@mui/icons-material';


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [lessons, setLessons] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedCourse, setEditedCourse] = useState({ ...row });
  const [fee, setFee] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [featuredCourseChecked, setFeaturedCourseChecked] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [editedLesson, setEditedLesson] = useState({}); 
 const [options, setOptions] = useState({
  option1: '',
  option2: '',
  option3: '',
  option4: '',
});
const [editable, setEditable] = useState(true);
const [editedOptions, setEditedOptions] = useState({ ...options });
const [selectedOption, setSelectedOption] = useState('');
const [question, setQuestion] = useState(
  editedLesson.quiz && editedLesson.quiz.length > 0
    ? editedLesson.quiz[0].question || ''
    : ''
);
const [uploadedVideo, setUploadedVideo] = useState(null);
const [savingData, setSavingData] = useState(false);
const droppableId = `lessons-${row.id}`;
const [rowCount, setRowCount] = useState(0); // State variable to track the count of rows


useEffect(() => {
  const fetchLessons = async () => {
    try {
      const lessonSnapshot = await getDocs(collection(firestore, 'courses', row.id, 'lessons'));
      const lessonData = lessonSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      lessonData.sort((a, b) => a.lessonNo - b.lessonNo);

      console.log('Sorted lessonData:', lessonData);

      setLessons(lessonData);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };

  if (open) {
    fetchLessons();
  }
}, [open, row.id]);

  
 
  useEffect(() => {
    if (open) {
      fetchOptions();
    }
  }, [row.id, row.lessonId, open]);
  
  const handleAddRow = () => {
    // Increment the row count when a new row is added
    setRowCount(rowCount + 1);
    // Your code to add a new row goes here
  };
  const renderRowNumber = (index) => (
    <TableCell component="th" scope="row" style={{ fontSize: '18px', fontWeight: 'revert-layer' }}>
      {index + 1}
    </TableCell>
  );
  
  const handleEditDialogOpen = (lesson) => {
    setEditedLesson({ ...lesson }); // Set the lesson data to be edited
    setQuestion(lesson.quiz && lesson.quiz.length > 0 ? lesson.quiz[0].question || '' : ''); // Set the question
  
    // Check if quiz exists and has options
    if (lesson.quiz && lesson.quiz.length > 0 && Array.isArray(lesson.quiz[0].options)) {
      setOptions(lesson.quiz[0].options.reduce((acc, curr, index) => {
        acc[`option${index + 1}`] = curr;
        return acc;
      }, {}));
    } else {
      setOptions({
        option1: '',
        option2: '',
        option3: '',
        option4: ''
      });
    }
  
    setEditDialogOpen(true);
  };
  
  
  
  // Function to handle closing the edit dialog
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  // Function to handle saving the edited lesson data
  const handleSaveEditedLesson = async () => {
    setSavingData(true);
    try {
      const lessonDocRef = doc(firestore, 'courses', row.id, 'lessons', editedLesson.id);
  
      // Check if the video has changed
      if (uploadedVideo) {
        const videoUrl = await uploadLessonVideo(uploadedVideo); // Upload the new video
        editedLesson.lectures[0].videoUrl = videoUrl; // Update the videoUrl field in the lectures array
      }
  
      const updatedQuiz = [{
        question: question,
        options: Object.values(options),
        answer: selectedOption
      }];
  
      const updatedData = {
        lessonName: editedLesson.lessonName,
        lectures: editedLesson.lectures,
        quiz: updatedQuiz,
        lessonImage: editedLesson.lessonImage
      };
  
      // Update the lesson data in Firestore
      await updateDoc(lessonDocRef, updatedData);
  
      // Update the lesson data in state
      setEditedLesson(prevLesson => ({
        ...prevLesson,
        ...updatedData,
      }));
  
      // Close the edit dialog
      setEditDialogOpen(false);
      setSavingData(false);
      window.location.reload();
      toast.success('Lesson updated successfully');

    } catch (error) {
      toast.error(error);
      console.error('Error updating lesson:', error);
    }
  };
  
  
  



  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
 
  useEffect(() => {
    if (editDialogOpen) {
      fetchOptions();
    }
  }, [editDialogOpen]);

  const fetchOptions = async () => {
  try {
    const lessonDocRef = doc(firestore, 'courses', row.id, 'lessons', row.lessonId);
    const lessonDocSnap = await getDocs(lessonDocRef);
    const lessonData = lessonDocSnap.data();

    if (lessonData && lessonData.quiz) {
      const { options } = lessonData.quiz[0]; // Assuming only one quiz object exists
      setEditedOptions(options || []); // Initialize editedOptions state with fetched options data
    }
  } catch (error) {
    console.error('Error fetching options:', error);
  }
};
const handleOptionChange = (index, value) => {
  if (!editable) return; // Exit early if the TextField is not editable
  const updatedOptions = [...editedOptions];
  updatedOptions[index] = value;
  setEditedOptions(updatedOptions);
  
};
const handleCheckBoxChange = (optionText) => {
  setSelectedOption(optionText);
};
const uploadLessonImage = async (file) => {
  try {
    const storageRef = ref(storage, `lesson_images/${file.name}`);
    const uploadTaskSnapshot = await uploadBytesResumable(storageRef, file);
    const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading lesson image:', error);
    throw error;
  }
};
const handleLessonImageChange = async (acceptedFiles) => {
  try {
    if (acceptedFiles.length > 0) {
      const imageUrl = await uploadLessonImage(acceptedFiles[0]);
      setEditedLesson(prevLesson => ({ ...prevLesson, lessonImage: imageUrl }));
    }
  } catch (error) {
    console.error('Error handling lesson image change:', error);
  }
};


const { getRootProps: getMainImageRootProps, getInputProps: getMainImageInputProps } = useDropzone({
  onDrop: handleLessonImageChange,
});


const handleVideoChange = (acceptedFiles) => {
  // Update the uploadedVideo state to store the newly selected video
  setUploadedVideo(acceptedFiles[0]);

  // If a video is selected, update the editedLesson state to reflect the new video URL
  if (acceptedFiles.length > 0) {
    const videoUrl = URL.createObjectURL(acceptedFiles[0]);
    setEditedLesson(prevLesson => ({ ...prevLesson, videoUrl: videoUrl }));
  }
};

// UseDropzone hook for video picker
const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } = useDropzone({
  accept: "video/mp4,video/webm,video/ogg",
  onDrop: handleVideoChange
});
const uploadLessonVideo = async (file) => {
  try {
    // Assuming 'storage' is your Firestore storage reference
    const storageRef = ref(storage, `lesson_videos/${file.name}`);
    const uploadTaskSnapshot = await uploadBytesResumable(storageRef, file);

    // Get the download URL for the uploaded video
    const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

    return downloadURL;
  } catch (error) {
    console.error('Error uploading lesson video:', error);
    throw error;
  }
};
const handleDragEnd = async (result) => {
  if (!result.destination) return; // Exit if dropped outside the list

  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  // Reorder the lessons array based on the drag and drop result
  const reorderedLessons = Array.from(lessons);
  const movedLesson = reorderedLessons.splice(sourceIndex, 1)[0];
  reorderedLessons.splice(destinationIndex, 0, movedLesson);

  // Update the lessonNo field in Firestore for each lesson
  const batch = writeBatch(firestore);
  reorderedLessons.forEach((lesson, index) => {
    const lessonRef = doc(firestore, 'courses', row.id, 'lessons', lesson.id);
    batch.update(lessonRef, { lessonNo: index + 1 });
  });

  try {
    await batch.commit();
    console.log('Lesson order updated successfully in Firestore');
  } catch (error) {
    console.error('Error updating lesson order in Firestore:', error);
  }

  // Update the UI state with the reordered lessons
  setLessons(reorderedLessons);
};






const staticData = [
  // Static data for two rows
  { id: 'static1', lessonName: 'Static Lesson 1', lessonDate: { seconds: 1630368000 } },
  { id: 'static2', lessonName: 'Static Lesson 2', lessonDate: { seconds: 1630454400 } },
];


// Fetch video URL from lectures array
const videoUrl = editedLesson.lectures && editedLesson.lectures.length > 0
  ? editedLesson.lectures[0].videoUrl
  : '';

  return (
   <>
    <ToastContainer position="top-right" />
         {savingData && <Loader />}
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell >
          <Avatar   sx={{ width: 50, height: 50 }} alt={row.title} src={row.img} />
        </TableCell>
        <TableCell component="th" scope="row" sx={{fontSize:"17px",fontWeight:"500"}}>
          {row.title}
        </TableCell>
        <TableCell >{row.lessons}</TableCell>
       
      </TableRow>
      <TableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          <Typography variant="h5" gutterBottom component="div">
            Lessons
          </Typography>
          <Table>
            <DragDropContext onDragEnd={handleDragEnd}>
              {lessons && lessons.length > 0 && (
                <Droppable droppableId="lessons">
                  {(provided, snapshot) => (
                    <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                      {lessons.map((lesson, index) => (
                        <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
                          {(provided) => (
                    <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{cursor:"grab"}}>
                      {/* New TableCell to display row number */}
                      <TableCell sx={{cursor:"grab"}}>
                        <DragIndicator/>

                      </TableCell>
                      <TableCell align="center" style={{ fontSize: '18px', fontWeight: 'revert-layer' }}>
                        {index + 1}
                      </TableCell>
                      {/* Existing TableCell for lessonName */}
                      <TableCell component="th" scope="row" style={{ fontSize: '18px', fontWeight: 'revert-layer' }}>
                        {lesson.lessonName}
                      </TableCell>
                      {/* Existing TableCell for lessonDate */}
                      <TableCell component="th" scope="row" style={{ fontSize: '15px', fontWeight: 'revert-layer' }}>
                        {new Date(lesson.lessonDate.seconds * 1000).toLocaleString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        })}
                      </TableCell>
                      {/* Existing TableCell for edit button */}
                      {/* <TableCell align="center">
                        <IconButton aria-label="edit" size="small" onClick={() => handleEditDialogOpen(lesson)} style={{ backgroundColor: '#17C3B2' }}>
                          <EditIcon style={{ color: 'white' }} />
                        </IconButton>
                      </TableCell> */}
                     

                    </TableRow>
                  )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </Table>
  </Box>
</Collapse>
</TableCell>
</TableRow>
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}PaperProps={{
        style: {
            width: '70%', // Adjust the width as needed
            maxWidth: 'none' ,
            padding:"20px"
        },
    }}>
      <DialogTitle>Add Lesson</DialogTitle>
                <div className="lessons" >
        
                <div className="pickers">
    <div className="drag-main">
        <div className="drag-drop">
        <div {...getMainImageRootProps()} className="dropzone-container">
  <input {...getMainImageInputProps()} accept="image/*" />
  <div className="lesson-image-container" style={{ backgroundImage: `url(${editedLesson.lessonImage})` }}>
    {editedLesson.lessonImage ? null : (
      <p className="drag-drop-text">
        <img src="image.png" alt="" /> Select or Drop the Image here
      </p>
    )}
  </div>
</div>


        </div>
    </div>

    <div className="drag-main">
  <div className="drag-drop">
    <div {...getVideoRootProps()} className="dropzone-container">
      <input {...getVideoInputProps()} accept="video/mp4, video/webm, video/ogg" />
      <div className="video-preview">
        {uploadedVideo ? (
          <video src={URL.createObjectURL(uploadedVideo)} controls className="video-element" />
        ) : (
          videoUrl ? (
            <video src={videoUrl} controls className="video-element" />
          ) : (
            <p className="drag-drop-text">
              <img src="video-icon.png" alt="" /> Select or Drop the Video here
            </p>
          )
        )}
      </div>
    </div>
  </div>
</div>


</div>

        <div className="course-inputs-main">
            <div className="course-inputs">
            <TextField
            id="lessonName"
            label="Lesson Name"
            multiline
            maxRows={4}
            variant="filled"
            className="filled-multiline-flexible"
            value={editedLesson.lessonName || ''} // Populate with Firestore data
            onChange={(e) => setEditedLesson({ ...editedLesson, lessonName: e.target.value })} // Update lessonName field
          />
         <TextField
  id="title"
  label="Video Title"
  multiline
  maxRows={4}
  variant="filled"
  className="filled-multiline-flexible"
  value={editedLesson.lectures && editedLesson.lectures.length > 0 ? editedLesson.lectures[0].videoTitle || '' : ''} // Access the first item in the lectures array and retrieve its videoTitle
  onChange={(e) => setEditedLesson({
    ...editedLesson,
    lectures: [
      {
        ...editedLesson.lectures[0],
        videoTitle: e.target.value
      },
      ...editedLesson.lectures.slice(1) // Keep the other items in the array unchanged
    ]
  })}
/>

            </div>
            <div className="course-inputs">
           <TextField
               sx={{width:"50%"}}

  id="question"
  label="Question"
  multiline
  maxRows={4}
  variant='filled'
  value={question}
  onChange={(e) => setQuestion(e.target.value)} // Update the question state
/>

            </div>
            <div className="course-inputs">
            <div className="checkbox-wrapper-33" >
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
                checked={selectedOption === 'option1'}
                onChange={() => handleCheckBoxChange(options.option1)}
                id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
    <TextField
    sx={{width:"100%"}}
  id="option1"
  label="Option 1"
  multiline
  maxRows={4}
  variant='filled'
  value={options.option1 || ''}
  onChange={(e) => setOptions({ ...options, option1: e.target.value })}
/>


                  <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
                checked={selectedOption === 'option2'}
                onChange={() => handleCheckBoxChange(options.option2)}

                              id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
    <TextField
        sx={{width:"100%"}}

  id="option2"
  label="Option 2"
  multiline
  maxRows={4}
  variant='filled'
  value={options.option2 || ''}
  onChange={(e) => setOptions({ ...options, option2: e.target.value })}
/>
            </div>
            <div className="course-inputs">
            <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
                checked={selectedOption === 'option3'}
                onChange={() => handleCheckBoxChange(options.option3)}
                id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
    <TextField
        sx={{width:"100%"}}

  id="option3"
  label="Option 3"
  multiline
  maxRows={4}
  variant='filled'
  value={options.option3 || ''}
  onChange={(e) => setOptions({ ...options, option3: e.target.value })}
/>

                  <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
                checked={selectedOption === 'option4'}
                onChange={() => handleCheckBoxChange(options.option4)}
        
                     id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
    <TextField
        sx={{width:"100%"}}

  id="option4"
  label="Option 4"
  multiline
  maxRows={4}
  variant='filled'
  value={options.option4 || ''}
  onChange={(e) => setOptions({ ...options, option4: e.target.value })}
/>
            </div>
           
        </div>
       
    </div>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={handleSaveEditedLesson}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
   </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    lessons: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
  }).isRequired,
};

export default function EditLessons() {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setSavingData(true);
      try {
        const querySnapshot = await getDocs(query(collection(firestore, 'courses')));
        const fetchedData = [];

        querySnapshot.forEach((doc) => {
          const courseData = doc.data();
          const dateAdded = courseData.dateAdded.toDate().toLocaleDateString();

          fetchedData.push({
            id: doc.id,
            title: courseData.courseTitle,
            lessons: dateAdded,
            img: courseData.courseImage,
          });
        });

        fetchedData.sort((a, b) => new Date(b.lessons) - new Date(a.lessons));
        setData(fetchedData);
        setSavingData(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
               {savingData && <Loader />}

      <div className="course-main">
        <div className="course">
          <div className="course-header">
            <div className="container">
              <h3>
                Edit your Courses
              </h3>
            </div>
          </div>
          <div className="main-input-search">
            <div className="input-container">
              <input placeholder="Search something..." className="input" name="text" type="text" value={searchQuery} onChange={handleSearch} />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon">
                <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                <g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g>
                <g id="SVGRepo_iconCarrier">
                  <rect fill="white"></rect>
                  <path d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z"></path>
                </g>
              </svg>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{fontSize:"21px", fontWeight:"bolder"}}>Course Image</TableCell>
                  <TableCell sx={{fontSize:"21px", fontWeight:"bolder"}}>Course Title</TableCell>
                  <TableCell sx={{fontSize:"21px", fontWeight:"bolder"}}>No of Lessons</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .filter((row) =>
                    row.title.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((row) => (
                    <Row key={row.id} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
const Loader = () => {
  return (
    <div className="overlay">
<div className="loader-main">
              <div className="loader">
                  {[...Array(5)].map((_, index) => (
                      <div className="orbe" style={{'--index': index}} key={index}></div>
                  ))}
              </div>
          </div>
    </div>
  );
};
