import Sidebar from "../appBar/appbar";
// import Navbar from "../sidebar";
import Support from "./support";


export default function SupportMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Support/>
      </div>
        </>
    )
}