import React, { useState, useEffect } from "react";
import { auth, firestore } from "./firebase"; // Assuming you have initialized firestore
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardImg
} from "reactstrap";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../login/login.css"

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [savingData , setSavingData] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                navigate('/dashboard');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setSavingData(true);
    
        try {
            const querySnapshot = await getDocs(collection(firestore, "admin"));
            let isAdmin = false; 
    
            querySnapshot.forEach((doc) => {
                const adminData = doc.data();
                if (adminData.email === email && adminData.password === password) {
                    isAdmin = true; 
                    return;
                }
            });
    
            if (isAdmin) {
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        setSavingData(false);
                        navigate('/dashboard');
                    })
                    .catch(error => {
                        setSavingData(false);
                        displayError(error.message);
                    });
            } else {
                setSavingData(false);
                displayError("Invalid email or password");
            }
        } catch (error) {
            setSavingData(false);
            displayError("Error logging in. Please try again later.");
        }
    };
    
    
    const displayError = (errorMessage) => {
        toast.error(errorMessage);
    };
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return ( 
       <>
           {savingData && <Loader />}
           <ToastContainer position="top-right"/>
            <div className="background">
                <div className="login-box">
                    <div className="container">
                        <div className="row app-des">
                            <div className="col left-background ">
                                <h2>Lectura</h2>
                                <CardImg className="mobile-img" src="logo.svg" alt="mobile-App" />
                            </div>
                            <div className="col login-form">
                                <form onSubmit={handleLogin}>
                                    <h2 className="font-weight-bold mb-4">Login</h2>
                                    <FormGroup>
                                        <Label className="font-weight-bold mb-2">Email</Label>
                                        <Input
                                            className="mb-3"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Label className="font-weight-bold mb-2">Password</Label>
                                        <Input
                                            className="mb-3"
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                         <div className="checkbox-wrapper-33">
                                        <label className="checkbox" style={{marginLeft:"0"}}>
                                            <input
                                                className="checkbox__trigger visuallyhidden"
                                                type="checkbox"
                                                checked={showPassword}
                                                onChange={toggleShowPassword}
                                            />
                                            <span className="checkbox__symbol"style={{width:"20px",height:"20px"}}>
                                                <svg
                                                    aria-hidden="true"
                                                    className="icon-checkbox"
                                                    width="28px"
                                                    height="28px"
                                                    viewBox="0 0 28 28"
                                                    version="1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M4 14l8 7L24 7"></path>
                                                </svg>
                                            </span>
                                            <p className="checkbox__textwrapper"style={{fontSize:"12px"}}>Show Password</p>
                                        </label>
                                    </div>
                                    </FormGroup>
                                    <Button className="mt-3 btn" type="submit">
                                        Login to your account
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </>
    );
}

export default Login;

const Loader = () => {
    return (
        <div className="overlay">
            <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{'--index': index}} key={index}></div>
                    ))}
                </div>
            </div>
        </div>
    );
};
