

import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import { firestore, storage } from '../firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, increment, query, updateDoc } from 'firebase/firestore';
import { Add, AddBoxTwoTone, AddCircleSharp, AddReactionOutlined, AddToDrive, AddToDriveOutlined, Delete, Edit, EditTwoTone } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import "../dashboard/table.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const columns = [
    { id: 'title', label: 'Title', minWidth: 170, align:'center' },
    { id: 'announcements', label: 'Announcements', minWidth: 170, align: 'center' }, // Changed label to 'Announcements'
    { id: 'lessons', label: 'Date', minWidth: 100, align: 'center' },
    { id: 'action', label: 'Action', minWidth: 170, align: 'center' },
];

export default function Announcement() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteDocId, setDeleteDocId] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [uploadedFiles1, setUploadedFiles1] = useState([]);
    const [uploadedVideo1, setUploadedVideo1] = useState(null);
    const [addLessonDialogOpen, setAddLessonDialogOpen] = useState(false);
const [selectedRowId, setSelectedRowId] = useState(null);
const [savingData, setSavingData] = useState(false);
const [lessonName, setLessonName] = useState('');
const [question, setQuestion] = useState('');
const [option1, setOption1] = useState('');
const [option2, setOption2] = useState('');
const [option3, setOption3] = useState('');
const [option4, setOption4] = useState('');
const [correctAnswer, setCorrectAnswer] = useState('');
const [uploadedFile, setUploadedFile] = useState(null);
const [pdfDilog, setPdfDilogOpen] = useState(false);
const [lessonName2, setLessonName2] = useState('');
const [selectedAnnouncements, setSelectedAnnouncements] = useState([]);





const handleLessonNameChange = (e) => setLessonName(e.target.value);



useEffect(() => {
    const fetchData = async () => {
        setSavingData(true);
        try {
            const querySnapshot = await getDocs(query(collection(firestore, 'courses')));
            const fetchedData = [];

            for (const doc of querySnapshot.docs) {
                const clinicData = doc.data();
                const dateAdded = clinicData.dateAdded.toDate().toLocaleDateString();
                const announcementsQuerySnapshot = await getDocs(collection(doc.ref, 'announcements'));
                const announcementsLength = announcementsQuerySnapshot.size;

                fetchedData.push({
                    id: doc.id,
                    title: clinicData.courseTitle,
                    announcements: announcementsLength, // Store the length of announcements
                    lessons: dateAdded,
                });
            }
            fetchedData.sort((a, b) => new Date(b.lessons) - new Date(a.lessons));

            setData(fetchedData);
            setLoading(false);
            setSavingData(false);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, []);

   
const handleSaveFiles = async () => {
    try {
        setSavingData(true);

        // Check if the lesson name is not empty
        if (!lessonName.trim()) {
            console.error("Message cannot be empty.");
            return;
        }

        // Check if selectedRowId is null
        if (!selectedRowId) {
            console.error("Selected row ID is null.");
            return;
        }

        // Generate a unique video ID
        const videoId = generateUniqueId();

        // Construct the lesson data object
        const lessonData = {
            courseId: selectedRowId,
            datePublished: new Date(),
            postId: videoId,
            message: lessonName,
            likes:[],
        };

        // Add lesson data to Firestore
        const lessonsCollectionRef = collection(firestore, "courses", selectedRowId, "announcements");
        const lessonRef = await addDoc(lessonsCollectionRef, lessonData);
        
        // Log success message
       
        console.log("Lesson data saved successfully:", lessonRef.id);

        // Close the dialog and reset state
        setPdfDilogOpen(false);
        setSavingData(false);
        toast.success('Announcement added Successfully');
        // Reset lesson name state
        setLessonName('');

    } catch (error) {
        toast.error('Error addind announcement')
        // Log and handle errors
        console.error("Error adding lesson data: ", error);
        setSavingData(false);
    }
};

    
    const generateUniqueId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 20; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = data.filter((row) =>
    Object.values(row).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDeleteClick = (docId) => {
        setDeleteDocId(docId);
        setDeleteDialogOpen(true);
    };

   
   

    const handleEditClick = (rowData) => {
        setEditedData(rowData);
        setEditDialogOpen(true);
    };
  
  
    
    useEffect(() => {
        if (!editDialogOpen) {
            // Reset all state variables for lesson form
            setLessonName('');
            setQuestion('');
            setOption1('');
            setOption2('');
            setOption3('');
            setOption4('');
            setCorrectAnswer('');
            setUploadedFiles1([]);
            setUploadedVideo1(null);
        }
    }, [editDialogOpen]);
    
    useEffect(() => {
        if (!pdfDilog) {
            // Reset all state variables for resource form
            setLessonName2('');
            setUploadedFile(null);
        }
    }, [pdfDilog]);
    

    const handleEditCancel = () => {
        setEditDialogOpen(false);
        setPdfDilogOpen(false);
        
    };
    const handlePDF = (rowData) => {
        setPdfDilogOpen(rowData);
        setPdfDilogOpen(true);
    };
    const handleRowClick = (rowId) => {
        setSelectedRowId(rowId);
    };
    const handleAnnonce = async (rowId) => {
        setSelectedRowId(rowId);
        try {
            const announcementsRef = collection(firestore, "courses", rowId, "announcements");
            const announcementsSnapshot = await getDocs(announcementsRef);
            const announcementsData = announcementsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSelectedAnnouncements(announcementsData);
            setEditDialogOpen(true); // Open the dialog box for editing announcements
        } catch (error) {
            console.error("Error fetching announcements:", error);
        }
    };
    
    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000); // Convert timestamp to milliseconds
        const day = date.getDate(); // Get the day of the month
        const month = date.toLocaleString('default', { month: 'short' }); // Get the short month name
        const year = date.getFullYear(); // Get the year
        let hours = date.getHours(); // Get the hours
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Get the minutes, padded with zero if needed
        const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine if it's AM or PM
        hours = hours % 12 || 12; // Convert hours to 12-hour format
        const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year} at ${hours}:${minutes}${ampm}`;
        return formattedDate;
    };
    
    const getOrdinalSuffix = (day) => {
        if (day === 1 || day === 21 || day === 31) return 'st';
        if (day === 2 || day === 22) return 'nd';
        if (day === 3 || day === 23) return 'rd';
        return 'th';
    };
    
    const handleDeleteAnnouncement = async (announcementId) => {
        try {
            await deleteDoc(doc(firestore, "courses", selectedRowId, "announcements", announcementId));
            // Remove the deleted announcement from the selectedAnnouncements state
            setSelectedAnnouncements(prevAnnouncements => {
                return prevAnnouncements.filter(announcement => announcement.id !== announcementId);
            });
            toast.success('Announcement deleted Successfully');
        } catch (error) {
            console.error('Error deleting announcement:', error);
            toast.error('Error deleting announcement');
        }
    };
    
    return (
        
       <>
               <ToastContainer position="top-right" />

        {savingData && <Loader />}
       <div className="course-main">
        <div className="course">
        <div className="course-header">
        <div class="container">
    <h3>Add Announcements</h3>
</div>
                      
                    </div>
                    <div className="main-input-search">
                    <div className="input-container">
            <input placeholder="Search something..." className="input" name="text" type="text"   value={searchQuery}
                onChange={handleSearch} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon">
                <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                <g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g>
                <g id="SVGRepo_iconCarrier">
                    <rect fill="white"></rect>
                    <path d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z" clipRule="evenodd" fillRule="evenodd"></path>
                </g>
            </svg>
        </div>
                    </div>
               
             <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
          
            <TableContainer sx={{ maxHeight: "55vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight: 'bolder',fontSize:"21px" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filteredData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => handleRowClick(row.id)}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align} style={{ fontWeight: "bold" }}>
                                                            {column.id === 'action' ? (
                                                                <div>
                                                                    <Tooltip title="Add announcement">
                                                                        <IconButton onClick={() => handlePDF(row.id)} style={{ backgroundColor: "#17C3B2", marginRight: "10px" }}>
                                                                            <AddBoxTwoTone style={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Edit Announcements">
    <IconButton onClick={() => handleAnnonce(row.id)} style={{ backgroundColor: "#17C3B2", marginRight: "10px" }}>
        <EditTwoTone style={{ color: "white" }} />
    </IconButton>
</Tooltip>

                                                                </div>
                                                            ) : (
                                                                // Render the length of announcement documents instead of the image
                                                                column.id === 'announcements' ? row.announcements : value
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
           
            <Dialog open={pdfDilog} onClose={handleEditCancel}PaperProps={{
        style: {
            width: '70%', // Adjust the width as needed
            maxWidth: 'none',
            padding:"20px",
        },
    }}>
                <DialogTitle>Write Message</DialogTitle>
                <div className="lessons" >
       
        <div className="drop-main" >
         
        </div>
        <div className="course-inputs" style={{display:"felx",justifyContent:"center",width:"100%"}}>
                <TextField
                    id="lessonName"
                    label="Message"
                    multiline
                    rows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                    value={lessonName}
                    onChange={handleLessonNameChange}
                />
            </div>
        {/* Profile Image Picker */}
       
       
    </div>
                <DialogActions>
                    <Button onClick={handleEditCancel}>Cancel</Button>
                    <Button onClick={handleSaveFiles} color="primary">
    Add
</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen} onClose={handleEditCancel} PaperProps={{ style: { width: '70%', maxWidth: 'none', padding: "20px" }}}>
    <DialogTitle>Edit Announcements</DialogTitle>
    <DialogContent>
        {selectedAnnouncements.map(announcement => (
            <div key={announcement.id} style={{borderBottom:"1px solid grey",paddingBottom:"20px"}}>
<p>Date Time: {formatDate(announcement.datePublished)}</p>
                <p>Message: {announcement.message}</p>
                <Button variant="contained" color="primary" onClick={() => handleDeleteAnnouncement(announcement.id)}>Delete</Button>
            </div>
        ))}
    </DialogContent>
    <DialogActions>
        <Button onClick={handleEditCancel}>Cancel</Button>
    </DialogActions>
</Dialog>
        </Paper>
        </div>
       </div>
        
       </>
    );
}
const Loader = () => {
    return (
      <div className="overlay">
 <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{'--index': index}} key={index}></div>
                    ))}
                </div>
            </div>
      </div>
    );
};
