import Sidebar from "../appBar/appbar";
// import User from "./user";
import Verify from "./verify";


export default function VerifyMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Verify/>
      </div>
        </>
    )
}