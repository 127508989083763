import React from "react";

const VerifyIcon = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 116.87"
      width="20"
      height="20"
    >
      <defs>
        <style>
          {`
            .cls-1{fill:${selected ? "white" : "#00000066"};fill-rule:evenodd;}
            .cls-2{fill:${selected ? "white" : "white"};}
            .cls-3{stroke:${selected ? "white" : "black"}; fill: ${selected ? "#00000066" : "none"};}
          `}
        </style>
      </defs>
      <title>verified-symbol</title>
      <polygon
        className="cls-1"
        points="61.37 8.24 80.43 0 90.88 17.79 111.15 22.32 109.15 42.85 122.88 58.43 109.2 73.87 111.15 94.55 91 99 80.43 116.87 61.51 108.62 42.45 116.87 32 99.08 11.73 94.55 13.73 74.01 0 58.43 13.68 42.99 11.73 22.32 31.88 17.87 42.45 0 61.37 8.24 61.37 8.24"
      />
      <path
        className="cls-2"
        d="M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z"
      />
      <path
        className="cls-3"
        d="M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z"
      />
    </svg>
  );
};

export default VerifyIcon;
