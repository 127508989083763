import Sidebar from "../appBar/appbar";
import User from "./user";


export default function UsersMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <User/>
      </div>
        </>
    )
}