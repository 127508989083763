import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5hNKH4r8RDqgsQ-0vAIgB4R2jMkAoPB8",
  authDomain: "lectura-3344b.firebaseapp.com",
  databaseURL: "https://lectura-3344b-default-rtdb.firebaseio.com",
  projectId: "lectura-3344b",
  storageBucket: "lectura-3344b.appspot.com",
  messagingSenderId: "343496143693",
  appId: "1:343496143693:web:6b69250d1871840458fd04",
  measurementId: "G-9R6K2XEEP1",
};
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
export { app, firestore, storage, auth };
