import React from "react";

const Icon8 = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      xmlSpace="preserve"
    >
      <path
        d="M3.48667 20.9988C2.89111 20.9988 2.38311 20.8153 1.96267 20.4482C1.54311 20.0811 1.33333 19.6366 1.33333 19.1148V6.13983C0.951111 6.00139 0.633333 5.77701 0.38 5.4667C0.126667 5.1556 0 4.79901 0 4.39692V1.88406C0 1.36298 0.209778 0.918504 0.629333 0.550636C1.04889 0.183545 1.55689 0 2.15333 0H21.8467C22.4422 0 22.9502 0.183545 23.3707 0.550636C23.7902 0.917727 24 1.3622 24 1.88406V4.39809C24 4.80096 23.8733 5.15755 23.62 5.46786C23.3676 5.77818 23.0498 6.00256 22.6667 6.14099V19.1159C22.6667 19.637 22.4569 20.0815 22.0373 20.4494C21.6178 20.8165 21.1098 21 20.5133 21L3.48667 20.9988ZM2.66667 6.28215V19.0016C2.66667 19.2412 2.76489 19.4395 2.96133 19.5966C3.15778 19.7537 3.40178 19.8322 3.69333 19.8322H20.5147C20.7538 19.8322 20.9502 19.765 21.104 19.6304C21.2578 19.4959 21.3347 19.324 21.3347 19.1148V6.28215H2.66667ZM2.15333 5.11555H21.8467C22.0858 5.11555 22.2822 5.04827 22.436 4.91373C22.5898 4.7784 22.6667 4.60613 22.6667 4.39692V1.88406C22.6667 1.67485 22.5898 1.50297 22.436 1.36842C22.2822 1.23388 22.0858 1.1666 21.8467 1.1666H2.15333C1.91422 1.1666 1.71778 1.23388 1.564 1.36842C1.41022 1.50297 1.33333 1.67485 1.33333 1.88406V4.39809C1.33333 4.60808 1.41022 4.78035 1.564 4.91489C1.71778 5.04944 1.91422 5.11555 2.15333 5.11555ZM8.51333 11.5097H15.4867V10.4994H8.51333V11.5097Z"
        fill={selected ? "white" : "#00000066"}
        fill-opacity="1"
      ></path>
    </svg>
  );
};

export default Icon8;
