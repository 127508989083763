import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import { firestore, storage } from '../firebase';
import { addDoc, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, increment, query, updateDoc } from 'firebase/firestore';
import { Add, AddCircleSharp, AddReactionOutlined, AddToDrive, AddToDriveOutlined, Delete, Edit, ViewList } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import "../dashboard/table.css";
import { useNavigate } from 'react-router-dom';

const columns = [
    { id: 'title', label: 'Title', minWidth: 170, align:'center' },
    { id: 'img', label: 'Image', minWidth: 170, align: 'center' },
    { id: 'lessons', label: 'Date', minWidth: 100, align: 'center' },
    { id: 'action', label: 'Actions', minWidth: 170, align: 'center' },
];

export default function StickyHeadTable2() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteDocId, setDeleteDocId] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [uploadedFiles1, setUploadedFiles1] = useState([]);
    const [uploadedVideo1, setUploadedVideo1] = useState(null);
    const [addLessonDialogOpen, setAddLessonDialogOpen] = useState(false);
const [selectedRowId, setSelectedRowId] = useState(null);
const [savingData, setSavingData] = useState(false);
const [lessonName, setLessonName] = useState('');
const [question, setQuestion] = useState('');
const [option1, setOption1] = useState('');
const [option2, setOption2] = useState('');
const [option3, setOption3] = useState('');
const [option4, setOption4] = useState('');
const [correctAnswer, setCorrectAnswer] = useState('');
const [uploadedFile, setUploadedFile] = useState(null);
const [pdfDilog, setPdfDilogOpen] = useState(false);
const [lessonName2, setLessonName2] = useState('');
const [title,setTitle] = useState('');
const [lessonsDialogOpen, setLessonsDialogOpen] = useState(false);
    const [selectedRowLessons, setSelectedRowLessons] = useState([]);
    const [lessonNo,setLessonNo] = useState('');
    const [isChecked, setIsChecked] = useState(false);
   const navigate = useNavigate();




const handleLessonNameChange = (e) => setLessonName(e.target.value);
const handleQuestionChange = (e) => setQuestion(e.target.value);
const handleOption1Change = (e) => setOption1(e.target.value);
const handleOption2Change = (e) => setOption2(e.target.value);
const handleOption3Change = (e) => setOption3(e.target.value);
const handleOption4Change = (e) => setOption4(e.target.value);
const handleCorrectAnswerChange = (e) => setCorrectAnswer(e.target.value);
const handleLessonNameChange2 = (e) => setLessonName2(e.target.value);
const handleTitleChange = (e) => setTitle(e.target.value);
const handleLessonnoChange = (e) => setLessonNo(e.target.value);


useEffect(() => {
    const fetchData = async () => {
        setSavingData(true);
        try {
            const querySnapshot = await getDocs(query(collection(firestore, 'courses')));
            const fetchedData = [];

            querySnapshot.forEach((doc) => {
                const clinicData = doc.data();
                // Convert the date object to a string representation
                const dateAdded = clinicData.dateAdded.toDate().toLocaleDateString(); // Adjust this to your desired date format

                fetchedData.push({
                    id: doc.id,
                    title: clinicData.courseTitle,
                    lessons: dateAdded, // Use the converted date string here
                    img: clinicData.courseImage,
                });
            });

            // Sort fetchedData based on the 'lessons' property in descending order
            fetchedData.sort((a, b) => new Date(b.lessons) - new Date(a.lessons));

            setData(fetchedData);
            setLoading(false);
            setSavingData(false);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
}, []);


    const getVideoDuration = async (videoFile) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const durationInSeconds = Math.floor(video.duration);
                resolve(durationInSeconds);
            };
            video.onerror = reject;
            video.src = URL.createObjectURL(videoFile);
        });
      };
      const generateUniqueId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 20; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };
      const handleRowClick = (rowId) => {
        setSelectedRowId(rowId);
        // You can also open the dialog for adding lessons here if needed
    };
    const navigateLesson = (rowData) => {
        navigate(`/all-courses/${rowData}`);
    }
    const handleSaveToFirestore = async () => {
        try {
            setSavingData(true);
    
            const lessonImageFile = uploadedFiles1[0];
            const lessonImageStorageRef = ref(storage, `lesson_images/${lessonImageFile.name}`);
            await uploadBytes(lessonImageStorageRef, lessonImageFile);
            const lessonImageUrl = await getDownloadURL(lessonImageStorageRef);
    
            // Handle video upload
            const lessonVideoFile = uploadedVideo1;
            const lessonVideoStorageRef = ref(storage, `lesson_videos/${lessonVideoFile.name}`);
            await uploadBytes(lessonVideoStorageRef, lessonVideoFile);
            const lessonVideoUrl = await getDownloadURL(lessonVideoStorageRef);
    
            // Generate unique video ID
            const videoId = generateUniqueId();
            const seconds = await getVideoDuration(uploadedVideo1);
            const duration = seconds >= 60 ? Math.floor(seconds / 60) + " minutes" : seconds + " seconds";
            
            // Create lesson data
            const lessonData = {
                courseId: selectedRowId,
                lessonName,
                type: "Lesson",
                lessonDate: new Date(),
                lessonNo,
                quiz: [{
                    question,
                    options: [option1, option2, option3, option4],
                    answer: correctAnswer,
                }],
                lectures: [{
                    videoUrl: lessonVideoUrl,
                    videoLength: duration,
                    videoId: videoId,
                    videoTitle: title,
                }],
                lessonImage: lessonImageUrl,
                totalLectures: 1, 
            };
    
            // Add lesson data to Firestore
            const lessonsCollectionRef = collection(firestore, "courses", selectedRowId, "lessons");
            const lessonRef = await addDoc(lessonsCollectionRef, lessonData);
    
            // Update the document with lessonId
            await updateDoc(doc(firestore, "courses", selectedRowId, "lessons", lessonRef.id), {
                lessonId: lessonRef.id // Adding lessonId in lessons collection
            });
    
            console.log("Lesson data saved successfully:", lessonRef.id);
            setEditDialogOpen(false);
            setSavingData(false);
    
        } catch (error) {
            console.error("Error adding lesson data: ", error);
            setSavingData(false);
        }
    };
    
      const handleSaveFiles = async () => {
        try {
            setSavingData(true);
    
            if (!uploadedFile) {
                console.error("No file uploaded.");
                return;
            }
    
            // Handle file upload
            const lessonFile = uploadedFile;
            const lessonStorageRef = ref(storage, `lesson_files/${lessonFile.name}`);
            await uploadBytes(lessonStorageRef, lessonFile);
            const lessonFileUrl = await getDownloadURL(lessonStorageRef);
    
            // Convert file size to MB
            const fileSizeInMB = (lessonFile.size / (1024 * 1024)).toFixed(2); // Convert bytes to MB with 2 decimal places
            const videoId = generateUniqueId();
            
            // Create lesson data
            const fileDetails = {
                fileName: lessonFile.name,
                fileSize: fileSizeInMB + " MB", // Append "MB" to indicate megabytes
                fileType: lessonFile.type,
                fileID: videoId,
            };
    
            const resourcesCollectionRef = collection(firestore, "courses", selectedRowId, "resources");
    
            // Check if a document with the same courseId exists
            const querySnapshot = await getDocs(resourcesCollectionRef);
            let existingResourceId = null;
    
            querySnapshot.forEach(doc => {
                if (doc.data().courseId === selectedRowId) {
                    existingResourceId = doc.id;
                }
            });
    
            if (existingResourceId) {
                // Update existing document with new file details
                await updateDoc(doc(firestore, "courses", selectedRowId, "resources", existingResourceId), {
                    files: arrayUnion({
                        fileUrl: lessonFileUrl,
                        ...fileDetails
                    })
                });
                console.log("File added to existing resource document:", existingResourceId);
            } else {
                // Create a new document
                const resourcesData = {
                    courseId: selectedRowId,
                    files: [{
                        fileUrl: lessonFileUrl,
                        ...fileDetails
                    }]
                };
    
                // Add lesson data to Firestore
                const resourceRef = await addDoc(resourcesCollectionRef, resourcesData);
    
                // Update the document with resourceId
                await updateDoc(doc(firestore, "courses", selectedRowId, "resources", resourceRef.id), {
                    resourceId: resourceRef.id // Adding resourceId in resources collection
                });
    
                console.log("New resource document created:", resourceRef.id);
            }
    
            setPdfDilogOpen(false);
            setSavingData(false);
    
        } catch (error) {
            console.error("Error adding resource data: ", error);
            setSavingData(false);
        }
    };
    

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = data.filter((row) =>
    Object.values(row).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDeleteClick = (docId) => {
        setDeleteDocId(docId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            // Delete the document
            await deleteDoc(doc(collection(firestore, 'courses'), deleteDocId));
    
            // Delete documents in subcollections recursively
            const subcollections = ['lessons']; // Replace with your subcollection names
            for (const subcollectionName of subcollections) {
                const subcollectionRef = collection(doc(collection(firestore, 'courses'), deleteDocId), subcollectionName);
                const subDocsSnapshot = await getDocs(subcollectionRef);
                subDocsSnapshot.forEach(async (doc) => {
                    await deleteDoc(doc.ref);
                });
            }
    
            // Update UI
            const updatedData = data.filter((item) => item.id !== deleteDocId);
            setData(updatedData);
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
    };

    const handleEditClick = (rowData) => {
        setEditedData(rowData);
        setEditDialogOpen(true);
    };
  
    const handlePDFUpload = (acceptedFiles) => {
        const pdfFile = acceptedFiles[0];
        setUploadedFile(pdfFile);
    };
    
    useEffect(() => {
        if (!editDialogOpen) {
            // Reset all state variables for lesson form
            setLessonName('');
            setLessonNo('');
            setTitle('');
            setQuestion('');
            setOption1('');
            setOption2('');
            setOption3('');
            setOption4('');
            setCorrectAnswer('');
            setUploadedFiles1([]);
            setUploadedVideo1(null);
        }
    }, [editDialogOpen]);
    
    useEffect(() => {
        if (!pdfDilog) {
            // Reset all state variables for resource form
            setLessonName2('');
            setUploadedFile(null);
        }
    }, [pdfDilog]);
    

    const handleEditCancel = () => {
        setEditDialogOpen(false);
        setPdfDilogOpen(false);
        
    };
    const handlePDF = (rowData) => {
        setPdfDilogOpen(rowData);
        setPdfDilogOpen(true);
    };

 
    const { getRootProps: getVideoRootProps1, getInputProps: getVideoInputProps1 } = useDropzone({
        accept: "video/mp4,video/webm,video/ogg",
        onDrop: useCallback(acceptedFiles => {
            const videoFiles = acceptedFiles.filter(file => file.type.startsWith('video/'));
            if (videoFiles.length === 0) {
                alert("Please select a video file.");
                return;
            }
            setUploadedVideo1(acceptedFiles[0]);
        }, [])
    });

    const { getRootProps: getMainImageRootProps1, getInputProps: getMainImageInputProps1 } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles1(acceptedFiles);
        },
    });
    const { getRootProps: getFileRootProps, getInputProps: getFileInputProps } = useDropzone({
        accept: ['application/pdf', 'image/*', 'text/plain', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
        onDrop: (acceptedFiles) => {
            setUploadedFile(acceptedFiles[0]);
        },
    });
    const handleCheckboxChange = (option) => {
        setCorrectAnswer(option); // Set the correct answer when the checkbox is checked
    };
    const handleDeleteLesson = async (lessonId) => {
        try {
            setSavingData(true);
            await deleteDoc(doc(firestore, 'courses', selectedRowId, 'lessons', lessonId));
            // Refresh the lessons data after deletion
            handleLessonsDialogOpen(selectedRowId);
            setSavingData(false);
        } catch (error) {
            console.error('Error deleting lesson:', error);
        }
    };
    const handleLessonsDialogOpen = async (rowId) => {
        setSelectedRowId(rowId);
        try {
            setSavingData(true);
            const lessonsQuerySnapshot = await getDocs(collection(firestore, 'courses', rowId, 'lessons'));
            const lessonsData = [];
            lessonsQuerySnapshot.forEach((doc) => {
                lessonsData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setSelectedRowLessons(lessonsData);
            setLessonsDialogOpen(true);
            setSavingData(false);
        } catch (error) {
            console.error('Error fetching lessons data:', error);
        }
    };
    useEffect(() => {
        const fetchPaymentStatus = async () => {
          const paymentDocRef = doc(firestore, 'paymentMethodStatus', 'PCnOKFAL9jYkPt5peIkd');
          try {
            const docSnap = await getDoc(paymentDocRef);
            if (docSnap.exists()) {
              const data = docSnap.data();
              setIsChecked(data.stripeEnabled); // Set initial state based on Firestore value
            } else {
              console.error('No such document!');
            }
          } catch (error) {
            console.error('Error fetching document:', error);
            // Handle error appropriately (e.g., show error message)
          }
        };
    
        fetchPaymentStatus();
      }, []); // Empty dependency array ensures this runs only once on component mount
    
      const handleChange = async () => {
        const updatedValue = !isChecked; // Toggle the isChecked state
        setIsChecked(updatedValue); // Update UI immediately
    
        const paymentDocRef = doc(firestore, 'paymentMethodStatus', 'PCnOKFAL9jYkPt5peIkd');
        try {
          await updateDoc(paymentDocRef, {
            stripeEnabled: updatedValue
          });
          console.log('Payment method status updated successfully!');
        } catch (error) {
          console.error('Error updating payment method status:', error);
          // Handle error appropriately (e.g., show error message)
          setIsChecked(!updatedValue); // Revert UI state on error
        }
      };
      
    return (
        
       <>
        {savingData && <Loader />}
       <div className="course-main">
        <div className="course">
        <div className="course-header">
        <div className="container">
    <h3>All Courses</h3>
</div>
                      
                    </div>
                    <div class="main-input-search">
    <div class="input-container">
        <input 
            placeholder="Search something..." 
            class="input" 
            name="text" 
            type="text"   
            value={searchQuery}
            onChange={handleSearch} 
        />
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            class="icon"
            width="24" 
            height="24"
        >
            <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
            <g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g>
            <g id="SVGRepo_iconCarrier">
                <rect fill="white" width="24" height="24"></rect>
                <path 
                    d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z" 
                    clipRule="evenodd" 
                    fillRule="evenodd"
                />
            </g>
        </svg>
    </div>
</div>



                    <div className='payment-checkbox'>
      <p>
        {isChecked ? 'Turn off Payment method(Stripe)' : 'Turn on Payment method (Stripe)'}
      </p>
      <div className="checkbox-wrapper">
        <label className="toggleButton">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            // disabled={!isChecked} // Disable checkbox until Firestore update completes
          />
          <div>
            <svg viewBox="0 0 44 44">
              <path transform="translate(-2.000000, -2.000000)" d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758"></path>
            </svg>
          </div>
        </label>
      </div>
    </div>
               
             <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
          
            <TableContainer sx={{ maxHeight: "55vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight: 'bolder',fontSize:"21px",  }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filteredData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => handleLessonsDialogOpen(row.id)} style={{cursor:'pointer'}}>
            {columns.map((column) => {
                const value = row[column.id];
                return (
                    <TableCell key={column.id} align={column.align} style={{ fontWeight: "bold" }}>
                        {column.id === 'img' ? (
                            <img
                                src={value}
                                alt="Course Image"
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    borderRadius: '50%',  // Apply border-radius to make the image round
                                    objectFit: 'cover'   // Ensure the image covers the entire space inside the round border
                                }}
                            />
                        ) : column.id === 'action' ? (
                            <div className='actions'>
                                <Tooltip title="Add Resources">
                                    <IconButton onClick={(e) => { e.stopPropagation(); handlePDF(row.id);handleRowClick(row.id) }} style={{ backgroundColor: "#17C3B2", marginRight: "10px" }}>
                                        <AddToDriveOutlined style={{ color: "white" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Add Lesson">
                                    <IconButton onClick={(e) => { e.stopPropagation(); navigateLesson(row.id); }} style={{ backgroundColor: "green" }}>
                                        <AddCircleSharp style={{ color: "white" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Course">
                                    <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteClick(row.id);handleRowClick(row.id) }} style={{ backgroundColor: "red", marginLeft: "10px" }}>
                                        <Delete style={{ color: "white" }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            value
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    ))}

</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle style={{ paddingTop: "20px" }}>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ paddingLeft: "40px", paddingRight: "40px" }}>Are you sure you want to delete this item?</DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: "20px", display: "flex", justifyContent: "space-evenly" }}>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editDialogOpen} onClose={handleEditCancel}PaperProps={{
        style: {
            width: '70%', // Adjust the width as needed
            maxWidth: 'none' ,
            padding:"20px"
        },
    }}>
                <DialogTitle>Add Lesson</DialogTitle>
                <div className="lessons" >
        
        <div className="drop-main">
            <div className="pickers">
                <div className="drag-main">
                    <div className="drag-drop">
                        <div {...getMainImageRootProps1()} className="dropzone-container">
                            <input {...getMainImageInputProps1()} />
                            {uploadedFiles1.length === 0 ? (
                                <p className="drag-drop-text">
                                    <img src="image.png" alt="" /> Select or Drop the Image here
                                </p>
                            ) : null}
                            {uploadedFiles1.map((file) => (
                                <div key={file.name} className="drop-img">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        className="uploaded-image"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="drag-main">
                    <div className="drag-drop">
                        <div {...getVideoRootProps1()} className="dropzone-container">
                            <input {...getVideoInputProps1()} />
                            {!uploadedVideo1 ? (
                                <p className="drag-drop-text">
                                    <img src="upload.png" alt="" /> Select or Drop the Video here
                                </p>
                            ) : (
                                <div className="drop-img">
                                    <video className="uploaded-video" controls>
                                        <source src={URL.createObjectURL(uploadedVideo1)} type={uploadedVideo1.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Profile Image Picker */}
        <div className="course-inputs-main">
            <div className="course-inputs">
                <TextField
                    id="lessonName"
                    label="Lesson Name"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                    value={lessonName}
                    onChange={handleLessonNameChange}
                />
                 <TextField
                   id="title"
                   label="Video Title"
                   multiline
                   maxRows={4}
                   variant='filled'
                   className="filled-multiline-flexible"
                   value={title}
                   onChange={handleTitleChange}
                  
                />
            </div>
            <div className="course-inputs">
               
                 <TextField
                   id="lessonno"
                   label="Lesson No"
                   multiline
                   maxRows={4}
                   variant='filled'
                   className="filled-multiline-flexible"
                   value={lessonNo}
                   onChange={handleLessonnoChange}
                  
                />
            </div>
          <div className="multiple-questions" >
          <div className="course-inputs">
                <TextField
              
                    id="question"
                    label="Question"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible1"
                    value={question}
                    onChange={handleQuestionChange}
                />
              
            </div>
            <div className="course-inputs">
            <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
              onChange={() => handleCheckboxChange(document.getElementById('option1').value)}
              id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
                <TextField
                    id="option1"
                    label="Option 1"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                    value={option1}
                    onChange={handleOption1Change}
                />
                  <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
              onChange={() => handleCheckboxChange(document.getElementById('option2').value)}
              id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
                <TextField
                    id="option2"
                    label="Option 2"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                    value={option2}
                    onChange={handleOption2Change}

                />
            </div>
            <div className="course-inputs">
            <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
              onChange={() => handleCheckboxChange(document.getElementById('option3').value)}
              id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
                <TextField
                    id="option3"
                    label="Option 3"
                    multiline
                    maxRows={4}
                    variant='filled'
                    value={option3}
                    className="filled-multiline-flexible"
                    onChange={handleOption3Change}

                />
                  <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="radio"
                name="option"
              onChange={() => handleCheckboxChange(document.getElementById('option4').value)}
              id="options"
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
        </label>
    </div>
                <TextField
                    id="option4"
                    label="Option 4"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                    value={option4}
                    onChange={handleOption4Change}

                />
            </div>
          </div>
           
        </div>
       
    </div>
                <DialogActions>
                    <Button onClick={handleEditCancel}>Cancel</Button>
                    <Button onClick={handleSaveToFirestore} color="primary">
    Add
</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={pdfDilog} onClose={handleEditCancel}PaperProps={{
        style: {
            width: '70%', // Adjust the width as needed
            maxWidth: 'none',
            padding:"20px",
        },
    }}>
                <DialogTitle>Add Resources</DialogTitle>
                <div className="lessons" >
       
        <div className="drop-main" >
            <div className="pickers">
               
            <div className="drag-main">
            <div className="drag-drop" style={{ overflow: "hidden" }}>
                <div {...getFileRootProps()} className="dropzone-container">
                    <input {...getFileInputProps()} />
                    {!uploadedFile ? (
                        <p className="drag-drop-text">
                            <img src="folder.png" alt="" /> Select or Drop the File here
                        </p>
                    ) : (
                        <div className="drop-img">
                            {uploadedFile.type === 'application/pdf' ? (
                                <embed src={URL.createObjectURL(uploadedFile)} type="application/pdf" width="100%" height="600px" />
                            ) : (
                                <p>Uploaded File: {uploadedFile.name}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
            </div>
        </div>

        {/* Profile Image Picker */}
       
       
    </div>
                <DialogActions>
                    <Button onClick={handleEditCancel}>Cancel</Button>
                    <Button onClick={handleSaveFiles} color="primary">
    Add
</Button>
                </DialogActions>
            </Dialog>
            {selectedRowLessons.length > 0 && (
                <Dialog open={lessonsDialogOpen} onClose={() => setLessonsDialogOpen(false)}>
                    <DialogTitle>Lessons</DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Lesson Name</TableCell>
                                        <TableCell>Date Time</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedRowLessons.map((lesson) => (
                                        <TableRow key={lesson.id}>
                                            <TableCell>{lesson.lessonName}</TableCell>
                                            <TableCell>{lesson.lessonDate.toDate().toLocaleString()}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => handleDeleteLesson(lesson.id)}
                                                    color="error"
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setLessonsDialogOpen(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Paper>
        </div>
       </div>
        
       </>
    );
}
const Loader = () => {
    return (
      <div className="overlay">
 <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{'--index': index}} key={index}></div>
                    ))}
                </div>
            </div>
      </div>
    );
};
