import React from "react";

const Icon7 = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="26"
      height="27"
      viewBox="0 0 26 27"
      xmlSpace="preserve"
    >
      <path
        d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75V0.25ZM3.66667 1L4.41446 0.942478C4.3844 0.55173 4.05857 0.25 3.66667 0.25V1ZM25 5L25.7454 5.08282C25.769 4.8708 25.7011 4.65877 25.5588 4.49981C25.4166 4.34085 25.2133 4.25 25 4.25V5ZM3.97436 5L3.22657 5.05752L3.97436 5ZM21.4515 17.1582L21.505 17.9063L21.4515 17.1582ZM7.64539 18.1444L7.69883 18.8925L7.64539 18.1444ZM1 1.75H3.66667V0.25H1V1.75ZM7.69883 18.8925L21.505 17.9063L21.3981 16.4101L7.59196 17.3963L7.69883 18.8925ZM24.6573 14.8756L25.7454 5.08282L24.2546 4.91718L23.1665 14.71L24.6573 14.8756ZM2.91888 1.05752L3.22657 5.05752L4.72215 4.94248L4.41446 0.942478L2.91888 1.05752ZM3.22657 5.05752L4.0488 15.7465L5.54438 15.6315L4.72215 4.94248L3.22657 5.05752ZM25 4.25H3.97436V5.75H25V4.25ZM21.505 17.9063C23.1493 17.7889 24.4753 16.5141 24.6573 14.8756L23.1665 14.71C23.0644 15.6291 22.3205 16.3442 21.3981 16.4101L21.505 17.9063ZM7.59196 17.3963C6.54018 17.4714 5.62525 16.6828 5.54438 15.6315L4.0488 15.7465C4.19296 17.6207 5.82392 19.0264 7.69883 18.8925L7.59196 17.3963Z"
        fill={selected ? "white" : "#00000066"}
        fill-opacity="1"
      ></path>
      <rect
        x="8.3335"
        y="24.3334"
        width="0.0133333"
        height="0.0133333"
        stroke={selected ? "white" : "#00000066"}
        stroke-opacity="1"
        stroke-width="4"
        stroke-linejoin="round"
      />
      <rect
        x="20.3335"
        y="24.3334"
        width="0.0133333"
        height="0.0133333"
        stroke={selected ? "white" : "#00000066"}
        stroke-opacity="1"
        stroke-width="4"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Icon7;
