import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Description } from '@mui/icons-material';
import { collection, getDocs, query, where, doc, updateDoc, addDoc, setDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Import your Firebase configuration
import { Document, Page, pdfjs } from 'react-pdf';
import { ButtonGroup } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Row(props) {
  const { row, onUpdateStatus } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track selected file index
  const [balance, setBalance] = useState('');
  const [userDetails, setUserDetails] = useState([]);
  const [savingData, setSavingData] = useState(false);

  const handlePreview = async (file) => {
    const pdf = await pdfjs.getDocument(file.fileURL).promise;
    setSelectedFile({ ...file, totalPages: pdf.numPages });
    setCurrentPage(1);
  };

  const handleStatusChange = async (index, newStatus) => {
    setSavingData(true);
    try {
      // Make a shallow copy of the listOfFiles array
      const updatedListOfFiles = [...row.listOfFiles];
      // Update the status of the specified file within the array
      updatedListOfFiles[index].status = newStatus;
      // Update the document in Firestore with the updated listOfFiles
      await updateDoc(doc(firestore, 'adminSupportPDF', row.userId), {
        listOfFiles: updatedListOfFiles,
      });
  
      // If the status changed from 'Rejected' to 'Verified', update the userDetails state
      if (row.listOfFiles[index].status === 'Rejected' && newStatus === 'Verified') {
        const updatedUserDetails = [...userDetails];
        updatedUserDetails[row.index].listOfFiles[index].status = newStatus;
        setUserDetails(updatedUserDetails);
      }
  
      setSelectedFile(null);
      setSavingData(false);
      setTimeout(() => {
      toast.success('Status updated successfully');
    }, 0);
    } catch (error) {
      setTimeout(() => {
      toast.error(error);
    }, 0);
      console.error('Error updating status:', error);
    }
  };
  const handleSendBalance = async () => {
    setSavingData(true); // Set savingData to true before the operation
    try {
      const walletDocRef = doc(firestore, 'wallet', row.userId);
      const walletDocSnapshot = await getDoc(walletDocRef);
      let currentBalance = 0;
      if (walletDocSnapshot.exists()) {
        const walletData = walletDocSnapshot.data();
        if (walletData.balance) {
          currentBalance = parseFloat(walletData.balance); // Parse the existing balance as a float
        }
      }
      const newBalance = currentBalance + parseFloat(balance); // Add the new balance to the existing balance
      await setDoc(walletDocRef, {
        userId: row.userId,
        balance: newBalance
      }, { merge: true }); // Use merge option to merge the new data with existing data without overwriting it
      setBalance('');
      setSelectedFile(null);
      setTimeout(() => {
        toast.success('Points sent successfully');
      }, 0); // Delay the toast message
    } catch (error) {
      setTimeout(() => {
      toast.error(error);
    }, 0);
      console.error('Error sending points:', error);
    } finally {
      setSavingData(false);
    }
  };
  

  return (
   <>
           <ToastContainer position="top-right" />
    {savingData && <Loader />}
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.username}
        </TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">
  {row.phoneNumber ? row.phoneNumber : "Phone number not found"}
</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Admin Support
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                Shared by: {row.username}
              </Typography>
              <Table size="small" aria-label="files">
                <TableHead>
                  <TableRow>
                    <TableCell style={{fontSize:"15px",fontWeight:"bolder"}}>File Name</TableCell>
                    <TableCell style={{fontSize:"15px",fontWeight:"bolder"}}>Shared At</TableCell>
                    <TableCell style={{fontSize:"15px",fontWeight:"bolder"}}>Status</TableCell>
                    <TableCell style={{fontSize:"15px",fontWeight:"bolder"}}>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.listOfFiles.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>{file.fileName}</TableCell>
                      <TableCell>{file.sharedAt.toDate().toString()}</TableCell>
                      <TableCell>{file.status}</TableCell>
                      <TableCell>
                        <IconButton
                          style={{ backgroundColor: 'rgba(1, 203, 196, 1)' }}
                          onClick={() => {
                            setSelectedFile(file);
                            setSelectedIndex(index);
                            handlePreview(file);
                          }}
                        >
                          <Description style={{ color: "whitesmoke" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {selectedFile && (
        <Dialog open={true} onClose={() => setSelectedFile(null)} PaperProps={{
          style: {
            width: '70%', // Adjust the width as needed
            maxWidth: 'none',
            padding: "20px"
          },
        }}>
          <DialogTitle>File Details</DialogTitle>
          <DialogContent>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Document file={selectedFile.fileURL} style={{ width: "100%" }}>
                  <Page pageNumber={currentPage} renderMode="canvas" />
                </Document>
              </div>
              <p>Page {currentPage}</p>
              <ButtonGroup>
                <Button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous Page
                </Button>
                <Button
                // style={{backgroundColor:"rgba(1, 203, 196, 1)",color:"white",border:"none"}}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === selectedFile.totalPages}
                  color='primary'
                >
                  Next Page
                </Button>
              </ButtonGroup>
              <p>Shared At: {selectedFile.sharedAt.toDate().toString()}</p>
              <Button variant="contained" style={{backgroundColor:"rgba(1, 203, 196, 1)"}} href={selectedFile.fileURL} download>
                Download
              </Button>
              {/* Input field for balance amount */}
              {selectedFile.status === 'Verified' && (
                <div className='balance'>
                  <input type="number" placeholder="Enter Points" value={balance} onChange={(e) => setBalance(e.target.value)} />
                  <Button variant="contained" style={{backgroundColor:"rgba(1, 203, 196, 1)"}} onClick={handleSendBalance}>
                    Send
                  </Button>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonGroup>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleStatusChange(selectedIndex, 'Rejected')}
                disabled={selectedFile.status === 'Rejected'}
              >
                {selectedFile.status === 'Rejected' ? 'Rejected' : 'Reject'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleStatusChange(selectedIndex, 'Verified')}
                disabled={selectedFile.status === 'Verified'}
              >
                {selectedFile.status === 'Verified' ? 'Verified' : 'Verify'}
              </Button>
            </ButtonGroup>
            <Button onClick={() => setSelectedFile(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
   </>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
};

export default function CollapsibleTable() {
  const [userDetails, setUserDetails] = useState([]);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setSavingData(true);
      const adminSupportSnapshot = await getDocs(collection(firestore, 'adminSupportPDF'));
      const userDetailsPromises = adminSupportSnapshot.docs.map(async (doc, index) => {
        const userData = doc.data();
        const listOfFiles = [];

        // Fetch user details
        const userDetailsQuery = query(collection(firestore, 'userDetails'), where('userId', '==', doc.id));
        const userDocSnapshot = await getDocs(userDetailsQuery);

        // Check if user details exist before accessing
        if (!userDocSnapshot.empty) {
          const userDetailsData = userDocSnapshot.docs[0].data();

          // Push file details to listOfFiles
          for (const fileData of userData.listOfFiles) {
            listOfFiles.push({
              fileName: fileData.fileName,
              fileSize: fileData.fileSize,
              fileType: fileData.fileType,
              sharedAt: fileData.sharedAt,
              status: fileData.status,
              fileURL: fileData.fileURL,
            });
          }

          // Return user details along with the list of files
          return {
            userId: doc.id,
            username: userDetailsData.username,
            email: userDetailsData.email,
            phoneNumber: userDetailsData.phoneNumber,
            sharedBy: userDetailsData.sharedBy,
            status: userDetailsData.status, // Add status to userDetails
            listOfFiles: listOfFiles,
            index: index,
          };
        } else {
          // Handle case when user details are not found
          console.log('User details not found for userId:', doc.id);
          return null;
        }
      });
       setSavingData(false);
      const userDetailsData = await Promise.all(userDetailsPromises);
      setUserDetails(userDetailsData.filter(user => user !== null));
    };
    
    fetchData();
  }, []);

  const handleStatusUpdate = (updatedUserDetails) => {
    setUserDetails(updatedUserDetails);
  };

  return (
   <>
           <ToastContainer position="top-right" />
    {savingData && <Loader />}
    <div className="course-main">
      <div className="course">
        <div className="course-header" style={{ marginBottom: "50px" }}>
          <div className="container">
            <h3>Admin Support</h3>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align='left' sx={{ fontSize: "21px", fontWeight: "bolder" }}>Username</TableCell>
                <TableCell align='center' sx={{ fontSize: "21px", fontWeight: "bolder", paddingLeft: "15%" }}>Email</TableCell>
                <TableCell align='right' sx={{ fontSize: "21px", fontWeight: "bolder" }}>Phone Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {userDetails.map((user) => (
                <Row key={user.userId} row={user} onUpdateStatus={handleStatusUpdate} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
   </>
  );
}
const Loader = () => {
  return (
    <div className="overlay">
<div className="loader-main">
              <div className="loader">
                  {[...Array(5)].map((_, index) => (
                      <div className="orbe" style={{'--index': index}} key={index}></div>
                  ))}
              </div>
          </div>
    </div>
  );
};