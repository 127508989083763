import Sidebar from "../appBar/appbar";
// import Navbar from "../sidebar";
import StickyHeadTable from "./add-lessons";


export default function LessonsMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <StickyHeadTable/>
      </div>
        </>
    )
}