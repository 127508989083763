import React from "react";

const Icon12 = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      width="26"
      height="26"
      style={{ width: '1em', height: '1em', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }}
    >
      <path
        d="M896 554.666667v170.666666a85.333333 85.333333 0 0 1-85.333333 85.333334v42.666666a128 128 0 0 1-128 128h-128a42.666667 42.666667 0 0 1-42.666667-42.666666v-21.333334a21.333333 21.333333 0 0 1 21.333333-21.333333H682.666667a42.666667 42.666667 0 0 0 42.666666-42.666667v-42.666666a42.666667 42.666667 0 0 1-42.666666-42.666667v-256a42.666667 42.666667 0 0 1 42.666666-42.666667V341.333333A213.333333 213.333333 0 0 0 298.666667 341.333333v128a42.666667 42.666667 0 0 1 42.666666 42.666667v256a42.666667 42.666667 0 0 1-42.666666 42.666667H213.333333a85.333333 85.333333 0 0 1-85.333333-85.333334v-170.666666a85.333333 85.333333 0 0 1 85.333333-85.333334V341.333333a298.666667 298.666667 0 0 1 597.333334 0v128a85.333333 85.333333 0 0 1 85.333333 85.333334z"
        fill={selected ? "white" : "#00000066"}
      />
    </svg>
  );
};

export default Icon12;
