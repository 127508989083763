import { Suspense } from "react";
import Sidebar from "../appBar/appbar";
import FullPageProgress from "../../components/FullPageProgress";
import PreviewScreen from "../../components/PreviewScreen";


export default function SupportMain(){
    return(
        <>
      <div style={{display:"flex",flex: 1}}>

      <Sidebar/>


<div style={{display:"flex",flex:5, justifyContent:"center",alignContent:"center"}}>
      <Suspense fallback={<FullPageProgress />}>
              <PreviewScreen
                // authTokenByRoomCodeEndpoint="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXJzaW9uIjoyLCJ0eXBlIjoiYXBwIiwiYXBwX2RhdGEiOm51bGwsImFjY2Vzc19rZXkiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODciLCJyb2xlIjoiYnJvYWRjYXN0ZXIiLCJyb29tX2lkIjoiNjY1ZjY4MDJhNGMwNWFmOGNjM2M5OTk0IiwidXNlcl9pZCI6IjUxNTA2NWQzLWEzZjktNGZiYy05OWIzLTE1NzRjMDE3NzMzMSIsImV4cCI6MTcxODMwNzcwNCwianRpIjoiMzYzNGNjZDEtNTY5Yy00NGVkLWI5NzAtMmRhODE3ZTlmMzYyIiwiaWF0IjoxNzE4MjIxMzA0LCJpc3MiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODUiLCJuYmYiOjE3MTgyMjEzMDQsInN1YiI6ImFwaSJ9.pB6PciPxUiZ7iXw1m0ujfoMrFPkkiCnD4mBSZkobNko"
                authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
              />
            </Suspense>    
            </div>            
              </div>
        </>
    )
}