import Sidebar from "../appBar/appbar";
import Stage from "./stage";


export default function StageMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Stage/>
      </div>
        </>
    )
}