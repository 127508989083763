import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { firestore, storage } from "../firebase"; // Assuming you have initialized Firestore and Firebase Storage
import { addDoc, collection, doc, getDocs, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import "../products/products.css";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import { Delete, Edit } from "@mui/icons-material";

const columns = [
  { id: 'img', label: 'Category', minWidth: 170, align: 'center' },
  { id: 'title', label: 'Product Name', minWidth: 170, align: 'center' },
  { id: 'lessons', label: 'Product Price', minWidth: 100, align: 'center' },
  { id: 'action', label: 'Actions', minWidth: 170, align: 'center' },
];

export default function Products() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [savingData, setSavingData] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [editImageIndex, setEditImageIndex] = useState(0);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'products'), (snapshot) => {
      const fetchedData = [];
      snapshot.forEach((doc) => {
        const clinicData = doc.data();
        fetchedData.push({
          id: doc.id,
          title: clinicData.productName,
          lessons: clinicData.productprice,
          img: clinicData.categories,
          desc: clinicData.productDescription,
          productImages: clinicData.productImages,
        });
      });
      setData(fetchedData);
    });

    return () => unsubscribe();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { getRootProps: getMainImageRootProps, getInputProps: getMainImageInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
      setCurrentImageIndex(0); // Reset to first image when new images are dropped
    },
  });

  const handleNextImage = (e) => {
    e.stopPropagation(); // Prevent dropzone from opening
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % uploadedFiles.length);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation(); // Prevent dropzone from opening
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + uploadedFiles.length) % uploadedFiles.length);
  };

  const saveToFirestore = async () => {
    try {
      setSavingData(true);
      // Upload images to Firebase Storage
      const uploadedImageUrls = [];
      await Promise.all(uploadedFiles.map(async (file) => {
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(storageRef);
        uploadedImageUrls.push(downloadUrl);
      }));

      const videoId = generateUniqueId();

      const data = {
        productImages: uploadedImageUrls,
        categories: document.getElementById('Categories').value,
        productDescription: document.getElementById('ProductDescription').value,
        productName: document.getElementById('ProductName').value,
        productprice: document.getElementById('productPrice').value,
        dateAdded: new Date(),
        productCode: videoId,
        productRatings: 3,
        type: "product",
      };

      // Add data to Firestore and get the document reference
      const docRef = await addDoc(collection(firestore, "products"), data);

      // Include the document ID in the data object
      data.productdocId = docRef.id;

      // Update the document with the docId included
      await setDoc(doc(firestore, "products", docRef.id), data);
      setSavingData(false);
      toast.success('Product Successfully added');

      console.log("Document successfully written to Firestore!");

      // Reset uploadedFiles state after saving
      setUploadedFiles([]);
      document.getElementById('Categories').value = '';
      document.getElementById('ProductDescription').value = '';
      document.getElementById('ProductName').value = '';
      document.getElementById('productPrice').value = '';
    } catch (error) {
      console.error("Error writing document: ", error);
      toast.error('Error adding products');
    }
  };

  const generateUniqueId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleDifficultyLevelChange = (event) => {
    setDifficultyLevel(event.target.value);
  };

  useEffect(() => {
    const filteredData = data.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredData(filteredData);
  }, [data, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEdit = (product) => {
    console.log("Editing product:", product); // Debugging
    setEditProduct(product);
    setOpenDialog(true);
  };
   const handleNextEditImage = (e) => {
    e.stopPropagation();
    const totalImages = editProduct.productImages.length + uploadedFiles.length;
    setEditImageIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const handlePrevEditImage = (e) => {
    e.stopPropagation();
    const totalImages = editProduct.productImages.length + uploadedFiles.length;
    setEditImageIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditProduct(null);
    setUploadedFiles([]);
    setEditImageIndex(0); // Reset the image index when dialog closes
  }; 



  const handleSaveEdit = async () => {
    try {
      setSavingData(true);
      const updatedImageUrls = [...editProduct.productImages];
      
      if (uploadedFiles.length > 0) {
        await Promise.all(uploadedFiles.map(async (file) => {
          const storageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(storageRef, file);
          const downloadUrl = await getDownloadURL(storageRef);
          updatedImageUrls.push(downloadUrl);
        }));
      }
  
      const updatedProduct = {
        ...editProduct,
        productImages: updatedImageUrls,
        categories: document.getElementById('editCategories').value,
        productDescription: document.getElementById('editProductDescription').value,
        productName: document.getElementById('editProductName').value,
        productprice: document.getElementById('editProductPrice').value,
      };
  
      await updateDoc(doc(firestore, "products", editProduct.id), updatedProduct);
      setSavingData(false);
      toast.success('Product Successfully updated');
  
      // Update local data
      setData(data.map(item => item.id === editProduct.id ? updatedProduct : item));
      handleCloseDialog();
    } catch (error) {
      console.error("Error updating document: ", error);
      toast.error('Error updating product');
    }
  };
  const handleDeleteImage = (index) => {
    if (index < editProduct.productImages.length) {
      // Remove image from Firestore
      const updatedImages = editProduct.productImages.filter((_, i) => i !== index);
      setEditProduct({ ...editProduct, productImages: updatedImages });
    } else {
      // Remove image from uploadedFiles
      const updatedFiles = uploadedFiles.filter((_, i) => i !== (index - editProduct.productImages.length));
      setUploadedFiles(updatedFiles);
    }
  };
  
  return (
    <>
      <ToastContainer position="top-right" />
      {savingData && <Loader />}

      <div className="course-main">
        <div className="course">
          <div className="course-header">
            <div className="container">
              <h3>
                Add Products
              </h3>
            </div>
          </div>
          <div className="pickers">
            <div className="drag-main">
              <div className="drag-drop">
                <div {...getMainImageRootProps()} className="dropzone-container">
                  <input {...getMainImageInputProps()} />
                  {uploadedFiles.length === 0 ? (
                    <p className="drag-drop-text">
                      <img src="image.png" alt="" /> Select or Drop the Image here
                    </p>
                  ) : (
                    <div className="drop-img">
                      <img
                        src={URL.createObjectURL(uploadedFiles[currentImageIndex])}
                        alt={uploadedFiles[currentImageIndex].name}
                        className="uploaded-image"
                      />
                      {uploadedFiles.length > 1 && (
                        <div className="preview-buttons">
                          <button onClick={handlePrevImage}><img src="prev.png" alt="" />Previous</button>
                          <button onClick={handleNextImage}>Next<img src="next.png" alt="" /></button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="course-inputs-main" style={{ marginTop: "50px" }}>
            <div className="course-inputs">
              <TextField
                id="Categories"
                label="Category"
                multiline
                maxRows={4}
                variant='filled'
                className="filled-multiline-flexible"
              />
              <TextField
                id="ProductDescription"
                label="Product Description"
                multiline
                maxRows={4}
                variant='filled'
                className="filled-multiline-flexible"
              />
            </div>
            <div className="course-inputs">
              <TextField
                id="ProductName"
                label="Product Name"
                multiline
                maxRows={4}
                variant='filled'
                className="filled-multiline-flexible"
              />
              <TextField
                id="productPrice"
                label="Product Price"
                multiline
                maxRows={4}
                variant='filled'
                className="filled-multiline-flexible"
              />
            </div>
            <div className="course-inputs">
            </div>
          </div>
          <div className="save-button">
            <button className="submit" onClick={saveToFirestore}>Add</button>
          </div>

          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
            <TableContainer sx={{ maxHeight: "55vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "21px" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align} style={column.id === 'img' ? { fontWeight: 'bold', fontSize: '17px' } : {}}>
                              {column.id === 'action' ? (
                                <div>
                                  <Tooltip title="Edit Product">
                                    <IconButton style={{ backgroundColor: "#17C3B2" }} onClick={() => handleEdit(row)}>
                                      <Edit style={{ color: "white" }} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

<Dialog open={openDialog} onClose={handleCloseDialog} PaperProps={{ style: { width: '60%', maxWidth: 'none', padding: "20px",height:"80%" } }}>
<DialogTitle>Edit Product</DialogTitle>
<DialogContent>
  {editProduct ? (
    <div>
      <TextField
        sx={{
          margin:"0 5px 5px 0"
        }}
        id="editCategories"
        label="Category"
        multiline
        maxRows={4}
        variant='filled'
        className="filled-multiline-flexible"
        defaultValue={editProduct.img || ''}
      />
      <TextField
        sx={{
          margin:"0 0 5px 5px"
        }}
        id="editProductDescription"
        label="Product Description"
        multiline
        maxRows={4}
        variant='filled'
        className="filled-multiline-flexible"
        defaultValue={editProduct.desc || ''}
      />
      <TextField
        sx={{
          margin:"0 5px 5px 0"
        }}
        id="editProductName"
        label="Product Name"
        multiline
        maxRows={4}
        variant='filled'
        className="filled-multiline-flexible"
        defaultValue={editProduct.title || ''}
      />
      <TextField
        sx={{
          margin:"0 0 5px 5px"
        }}
        id="editProductPrice"
        label="Product Price"
        multiline
        maxRows={4}
        variant='filled'
        className="filled-multiline-flexible"
        defaultValue={editProduct.lessons || ''}
      />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div className="drag-main" style={{ width: "50%" }}>
          <div className="drag-drop">
            <div {...getMainImageRootProps()} className="dropzone-container">
              <input {...getMainImageInputProps()} />
              {uploadedFiles.length === 0 && editProduct.productImages.length > 0 ? (
                <ImagePreview
                  images={editProduct.productImages}
                  currentIndex={editImageIndex}
                  onNext={handleNextEditImage}
                  onPrev={handlePrevEditImage}
                  onDelete={handleDeleteImage}
                />
              ) : (
                <ImagePreview
                  images={uploadedFiles.map(file => URL.createObjectURL(file))}
                  currentIndex={editImageIndex - editProduct.productImages.length}
                  onNext={handleNextEditImage}
                  onPrev={handlePrevEditImage}
                  onDelete={handleDeleteImage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  )}
</DialogContent>
<DialogActions>
  <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
  <Button onClick={handleSaveEdit} color="primary">Save</Button>
</DialogActions>
</Dialog>

        </div>
      </div>
    </>
  );
}

const Loader = () => {
  return (
    <div className="overlay">
      <div className="loader-main">
        <div className="loader">
          {[...Array(5)].map((_, index) => (
            <div className="orbe" style={{ '--index': index }} key={index}></div>
          ))}
        </div>
      </div>
    </div>
  );
};
const ImagePreview = ({ images, currentIndex, onNext, onPrev, onDelete }) => (
  <div className="drop-img">
    <img
      src={images[currentIndex]}
      alt={`Image ${currentIndex}`}
      className="uploaded-image"
    />
   <Tooltip title="Remove Image">
   <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onDelete(currentIndex);
        }}
      className="delete-icon"
      style={{ position: 'absolute', top: 0, right: 0 }}
    >
      <Delete style={{ color: "red" }} />
    </IconButton>
   </Tooltip>
    {images.length > 1 && (
      <div className="preview-buttons">
        <button onClick={onPrev}><img src="prev.png" alt="" />Previous</button>
        <button onClick={onNext}>Next<img src="next.png" alt="" /></button>
      </div>
    )}
  </div>
);
