import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import { firestore, storage } from '../firebase';
import { addDoc, collection, deleteDoc, doc, getDocs, increment, query, updateDoc } from 'firebase/firestore';
import { Add, AddCircleSharp, AddReactionOutlined, AddToDrive, AddToDriveOutlined, Delete, Edit } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import "../dashboard/table.css";

const columns = [
    { id: 'img', label: 'Stage', minWidth: 170, align: 'center' },
    { id: 'title', label: 'Premium Price', minWidth: 170, align:'center' },
    { id: 'lessons', label: 'Pro Price', minWidth: 100, align: 'center' },
    { id: 'action', label: 'Action', minWidth: 170, align: 'center' },
];

export default function Stage() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [proPrice, setProPrice] = useState('');
    const [premiumPrice, setPremiumPrice] = useState('');
    const [searchQuery, setSearchQuery] = useState('');





    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'stagePrice'));
                const fetchedData = [];

                querySnapshot.forEach((doc) => {
                    const clinicData = doc.data();

                    fetchedData.push({
                        id: doc.id,
                        title: clinicData.premiumPrice,
                        lessons: clinicData.proPrice,
                        img: clinicData.stageName,
                    });
                });

                setData(fetchedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleOpenDialog = (row) => {
        setSelectedRow(row);
        setProPrice(row.lessons);
        setPremiumPrice(row.title);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleUpdatePrices = async () => {
        try {
            await updateDoc(doc(firestore, 'stagePrice', selectedRow.id), {
                premiumPrice: parseFloat(premiumPrice),
                proPrice: parseFloat(proPrice),
            });
            // Update the data in the local state
            const updatedData = data.map((row) => {
                if (row.id === selectedRow.id) {
                    return {
                        ...row,
                        title: parseFloat(premiumPrice),
                        lessons: parseFloat(proPrice),
                    };
                }
                return row;
            });
            setData(updatedData);
            setOpenDialog(false);
        } catch (error) {
            console.error('Error updating prices:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

 

    const filteredData = data.filter((row) =>
    Object.values(row).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
);
    

   
    return (
        
       <>
       <div className="course-main">
        <div className="course">
        <div className="course-header">
        <div class="container">
    <h3>Stage Prices</h3>
</div>
                      
                    </div>
                    <div className="main-input-search">
                    <div className="input-container">
            <input placeholder="Search something..." className="input" name="text" type="text"   value={searchQuery}
                onChange={handleSearch} />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon">
                <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                <g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g>
                <g id="SVGRepo_iconCarrier">
                    <rect fill="white"></rect>
                    <path d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM9 11.5C9 10.1193 10.1193 9 11.5 9C12.8807 9 14 10.1193 14 11.5C14 12.8807 12.8807 14 11.5 14C10.1193 14 9 12.8807 9 11.5ZM11.5 7C9.01472 7 7 9.01472 7 11.5C7 13.9853 9.01472 16 11.5 16C12.3805 16 13.202 15.7471 13.8957 15.31L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L15.31 13.8957C15.7471 13.202 16 12.3805 16 11.5C16 9.01472 13.9853 7 11.5 7Z" clipRule="evenodd" fillRule="evenodd"></path>
                </g>
            </svg>
        </div>
                    </div>
               
             <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
          
            <TableContainer sx={{ maxHeight: "55vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight: 'bolder',fontSize:"21px",
                                }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
    {filteredData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >
                {columns.map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align} style={column.id === 'img' ? { fontWeight: 'bold', fontSize: '17px' } : {}}>
                        { column.id === 'action' ? (
                                <div>
                                  
                                  <Tooltip title="Change Price">
                                                            <IconButton onClick={() => handleOpenDialog(row)}style={{ backgroundColor: "#17C3B2"}}>
                                                                <Edit style={{ color: "white" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                   
                                </div>
                            ) : (
                                value
                            )}
                        </TableCell>
                    
                    );
                })}
            </TableRow>
        ))}
</TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
           <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Update Prices</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="proPrice"
                        label="Pro Price"
                        type="number"
                        fullWidth
                        value={proPrice}
                        onChange={(e) => setProPrice(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="premiumPrice"
                        label="Premium Price"
                        type="number"
                        fullWidth
                        value={premiumPrice}
                        onChange={(e) => setPremiumPrice(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleUpdatePrices}>Update</Button>
                </DialogActions>
            </Dialog>
           
        </Paper>
        </div>
       </div>
        
       </>
    );
}
const Loader = () => {
    return (
      <div className="overlay">
 <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{'--index': index}} key={index}></div>
                    ))}
                </div>
            </div>
      </div>
    );
};
