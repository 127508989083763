import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { OnlinePrediction } from "@mui/icons-material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const columns = [
    { id: 'email', label: 'Order type', minWidth: 170, align: 'center' },
    { id: 'orderDate', label: 'Order Date', minWidth: 200, align: 'center' },
    { id: 'totalPrice', label: 'Total Price', minWidth: 150, align: 'center' },
    { id: 'actions', label: 'Actions', minWidth: 100 },
];

function formatDate(timestamp) {
    const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JavaScript Date object
    const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
    return formattedDate;
}

function Status() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null); // State for managing selected order
    const [openDialog, setOpenDialog] = useState(false); // State for controlling dialog visibility
    const [status, setStatus] = useState(''); // State for managing order status
    const [userName, setUserName] = useState(''); // State for storing the username

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const userDetailsCollectionRef = collection(firestore, "orders");
                const querySnapshot = await getDocs(userDetailsCollectionRef);
                const fetchedUsers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                // Sort orders by orderDate in descending order
                fetchedUsers.sort((a, b) => b.orderDate.seconds - a.orderDate.seconds);
                setUsers(fetchedUsers);
            } catch (error) {
                toast.error("Error fetching users");
            }
            setLoading(false);
        };
        fetchUsers();
    }, []);
    

    useEffect(() => {
        const fetchUserName = async () => {
            if (selectedOrder) {
                try {
                    const userDetailsDoc = await getDoc(doc(firestore, "userDetails", selectedOrder.orderBy));
                    if (userDetailsDoc.exists()) {
                        const userData = userDetailsDoc.data();
                        setUserName(userData.username); // Assuming username is a field in userDetails document
                    }
                } catch (error) {
                    toast.error("Error fetching username");
                }
            }
        };
        fetchUserName();
    }, [selectedOrder]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpenDialog = (order) => {
        setSelectedOrder(order);
        setStatus(order.status); // Set initial status based on selected order's status
        setOpenDialog(true);
    };
    
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleStatusChange = (newStatus) => {
        // Update Firestore with new status
        const orderRef = doc(firestore, "orders", selectedOrder.id); // Using selectedOrder.id to get the correct document ID
        updateDoc(orderRef, { status: newStatus })
            .then(() => {
                toast.success("Status updated successfully");
                setStatus(newStatus); // Update local state after successful Firestore update
            })
            .catch((error) => {
                toast.error("Error updating status: " + error.message);
            });
    };
    
    return (
        <>
            <ToastContainer position="top-right" />
            <div className="course-main">
                <div className="course">
                    <div className="course-header" style={{marginBottom:"60px"}}>
                        <div className="container">
                            <h3>Order Status</h3>
                        </div>
                    </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: "55vh" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "21px" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={user.id} align="center">
                                                <TableCell align="center">{user.orderType}</TableCell>
                                                <TableCell align="center">{formatDate(user.orderDate)}</TableCell>
                                                <TableCell align="center">{user.totalPrice}</TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Change order status">
                                                        <IconButton
                                                            style={{ backgroundColor: "#17C3B2" }}
                                                            onClick={() => handleOpenDialog(user)}
                                                        >
                                                            <OnlinePrediction style={{ color: "white" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>

            {/* Dialog to display order details */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Order Details</DialogTitle>
                <DialogContent>
                    <p><strong>Order by:</strong> {userName}</p>
                    <p><strong>Order ID:</strong> {selectedOrder?.orderId}</p>
                    <p><strong>Payment ID:</strong> {selectedOrder?.paymentId}</p>
                    <p><strong>Product ID:</strong> {selectedOrder?.productId}</p>
                    <p><strong>Total Price:</strong> {selectedOrder?.totalPrice}</p>
                    <div>
                        <ToggleButtonGroup
                            value={status} // Ensure value is set to the status state
                            exclusive
                            onChange={(event, newStatus) => handleStatusChange(newStatus)}
                            style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}
                        >
                            <ToggleButton value="cancel" style={{ backgroundColor: status === "cancel" ? "#17C3B2" : "white",border:"none", color: status === "cancel" ? "white" : "#17C3B2" }}>
                                Cancel
                            </ToggleButton>
                            <ToggleButton value="pending" style={{ backgroundColor: status === "pending" ? "#17C3B2" : "white",border:"none",color: status === "pending" ? "white" : "#17C3B2" }}>
                                Pending
                            </ToggleButton>
                            <ToggleButton value="shipped" style={{ backgroundColor: status === "shipped" ? "#17C3B2" : "white",border:"none",color: status === "shipped" ? "white" : "#17C3B2" }}>
                                Shipped
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Status;
