import Sidebar from "../appBar/appbar";
// import Navbar from "../sidebar";
import Status from "./status";


export default function StatusMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Status/>
      </div>
        </>
    )
}