import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteUser } from "firebase/auth";

const columns = [
    { id: 'email', label: 'Email', minWidth: 170, align:'center' },
    { id: 'actions', label: 'Actions', minWidth: 100 },
];

function User() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const adminCollectionRef = collection(firestore, "admin");
                const querySnapshot = await getDocs(adminCollectionRef);
                const fetchedUsers = [];
                querySnapshot.forEach((doc) => {
                    if (doc.id !== "Zdeq3rUVIg6zRQPZAdsb") {
                        fetchedUsers.push({ id: doc.id, ...doc.data() });
                    }
                });
                setUsers(fetchedUsers);
            } catch (error) {
                toast.error("Error fetching users");
            }
            setLoading(false);
        };
    
        fetchUsers();
    }, []);
  
    
    const handleDeleteUser = async () => {
        try {
            await deleteDoc(doc(firestore, "admin", deleteUserId));
            setUsers(users.filter(user => user.id !== deleteUserId));
            toast.success("User deleted successfully");
        } catch (error) {
            toast.error("Error deleting user");
        }
        setDeleteDialogOpen(false);
        setDeleteUserId(null);
    };


    const handleOpenDeleteDialog = (userId) => {
        setDeleteDialogOpen(true);
        setDeleteUserId(userId);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteUserId(null);
    };

    const handleAddUser = () => {
        navigate('/signUp');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            <ToastContainer position="top-right" />
            <div className="course-main">
                <div className="course">
                    <div className="course-header">
                        <div className="container">
                            <h3>
                                Users
                            </h3>
                        </div>
                    </div>
                    <div className="save-button" style={{ justifyContent: "start", paddingLeft: "50px" }}>
                        <button className="submit" onClick={handleAddUser}>Add User</button>
                    </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: "55vh" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{ minWidth: column.minWidth, fontWeight: 'bolder',fontSize:"21px" }}
                                                >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user) => (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={user.id} align="center">
                                                <TableCell align="center">{user.email}</TableCell>
                                                <TableCell align="center">
                                                   <Tooltip title="Delete User">
                                                   <IconButton onClick={() => handleOpenDeleteDialog(user.id)} style={{ backgroundColor: "red" }}>
                                                        <Delete style={{ color: "white" }} />
                                                    </IconButton>
                                                   </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                    >
                        <DialogTitle>Delete User</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this user?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteDialog} >Cancel</Button>
                            <Button onClick={handleDeleteUser} autoFocus>Delete</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

export default User;
