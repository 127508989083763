import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import { collection, getDocs, doc, updateDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "@mui/material";
import Box from '@mui/material/Box';

const columns = [
    { id: 'phone', label: 'Phone', minWidth: 170, align: 'center' },
    { id: 'username', label: 'Username', minWidth: 170, align: 'center' },
    { id: 'idCardFrontPic', label: 'ID Card Front', minWidth: 100, align: 'center' },
    { id: 'verified', label: 'Verified', minWidth: 100, align: 'center' },
];

function Verify() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [toggleChecked, setToggleChecked] = useState(false);
    const [autoVerifyChecked, setAutoVerifyChecked] = useState(false); // New state for autoVerify toggle
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const userDetailsCollectionRef = collection(firestore, "userDetails");
                const querySnapshot = await getDocs(userDetailsCollectionRef);
                const fetchedUsers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(fetchedUsers);
            } catch (error) {
                toast.error("Error fetching users");
            }
            setLoading(false);
        };
        fetchUsers();
    }, []);

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangeVerification = async (userId, isChecked) => {
        try {
            setOpenDialog(false);
            const userRef = doc(firestore, "userDetails", userId);
            await updateDoc(userRef, { verified: isChecked });
            setUsers(prevUsers => prevUsers.map(user => user.id === userId ? { ...user, verified: isChecked } : user));
            toast.success(`User ${isChecked ? "verified" : "unverified"} successfully`);
        } catch (error) {
            toast.error(`Error ${isChecked ? "verifying" : "unverifying"} user`);
        }
    };

    const handleToggleChange = async () => {
        const newChecked = !toggleChecked;
        setToggleChecked(newChecked);
        try {
            const batch = [];
            users.forEach(user => {
                const userRef = doc(firestore, "userDetails", user.id);
                batch.push(updateDoc(userRef, { verified: newChecked }));
            });
            await Promise.all(batch);
            setUsers(users.map(user => ({ ...user, verified: newChecked })));
            toast.success(`All users ${newChecked ? "verified" : "unverified"} successfully`);
        } catch (error) {
            toast.error(`Error ${newChecked ? "verifying" : "unverifying"} all users`);
        }
    };
    useEffect(() => {
        const fetchAutoVerify = async () => {
            try {
                const autoVerifyDocRef = doc(firestore, "autoVerify", "vj1a9qlwkDDAkKVca25i");
                const autoVerifyDoc = await getDoc(autoVerifyDocRef);
                if (autoVerifyDoc.exists()) {
                    setAutoVerifyChecked(autoVerifyDoc.data().autoVerify);
                }
            } catch (error) {
                toast.error("Error fetching auto verify status");
            }
        };
        fetchAutoVerify();
    }, []);
    const handleAutoVerifyChange = async () => {
        const newChecked = !autoVerifyChecked;
        setAutoVerifyChecked(newChecked);
        try {
            const autoVerifyDocRef = doc(firestore, "autoVerify", "vj1a9qlwkDDAkKVca25i");
            await updateDoc(autoVerifyDocRef, {
                autoVerify: newChecked,
            });
            toast.success(`Auto Verify ${newChecked ? "enabled" : "disabled"} successfully`);
        } catch (error) {
            toast.error(`Error ${newChecked ? "enabling" : "disabling"} auto verify`);
        }
    };
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <>
            <ToastContainer position="top-right" />
            <div className="course-main">
                <div className="course">
                    <div className="course-header" >
                        <div className="container">
                            <h3>
                                Users Verification
                            </h3>
                        </div>
                    </div>
                    <div className="user-verification">
                        <p>
                            Verify All Users
                        </p>
                        <div className="checkbox-wrapper">
                            <label className="toggleButton">
                                <input type="checkbox" checked={toggleChecked} onChange={handleToggleChange} />
                                <div>
                                    <svg viewBox="0 0 44 44">
                                        <path transform="translate(-2.000000, -2.000000)" d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758"></path>
                                    </svg>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className='payment-checkbox'>
                        <p>
                         Turn {autoVerifyChecked ? 'off' : 'on'} auto Verify
                        </p>
                       <div className="toggle-button-coverrrr">
                       <div id="button-3" className="button r">
                           <input
                               className="checkboxxxx"
                               type="checkbox"
                               checked={autoVerifyChecked} // New toggle state
                               onChange={handleAutoVerifyChange} // New toggle handler
                           />
                           <div className="knobs"></div>
                           <div className="layer"></div>
                       </div>
                   </div>
                   
                    </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: "55vh" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align="center"
                                                style={{ minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "21px" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user) => (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={user.id}
                                                align="center"
                                                onClick={() => handleRowClick(user)}
                                                sx={{
                                                    cursor:"pointer"
                                                }}
                                            >
                                                <TableCell align="center">{user.phoneNumber}</TableCell>
                                                <TableCell align="center">{user.username}</TableCell>
                                                <TableCell align="center">
                                                    <img src={user.idCardFrontPic} alt="ID Card Front" style={{ width: '50px', height: '50px' }} />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <label className="switch">
                                                        <input type="checkbox" className="chbox" checked={user.verified} onChange={(event) => handleChangeVerification(user.id, event.target.checked)} onClick={(e) => stopPropagation(e)} />
                                                        <div className="slider slider--0"> ✔</div>
                                                        <div className="slider slider--1">
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                        <div className="slider slider--2"></div>
                                                        <div className="slider slider--3">❌</div>
                                                    </label>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper> 
                </div>
            </div>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <Box p={4}>
                    {selectedUser && (
                        <div>
                            <h2>User Information</h2>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <h4>Front ID Card</h4>
                                    <img src={selectedUser.idCardFrontPic} alt="ID Card Front" style={{ width: '100%', maxWidth: '350px', maxHeight: "260px" }} />
                                </div>
                                <div>
                                    <h4>Back ID Card</h4>
                                    <img src={selectedUser.idCardBackPic} alt="ID Card Back" style={{ width: '100%', maxWidth: '350px', maxHeight: "260px" }} />
                                </div>
                            </div>
                            <p>Username: {selectedUser.username}</p>
                            <p>Phone Number: {selectedUser.phoneNumber}</p>
                            <p>Level: {selectedUser.level}</p>
                        </div>
                    )}
                </Box>
            </Dialog>
        </>
    );
}

export default Verify;
