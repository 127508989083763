import { useCallback, useEffect, useState } from "react";
import "./courses.css";
import { useDropzone } from "react-dropzone";
import { firestore, storage } from "../firebase"; // Import firestore from Firebase
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Timestamp, addDoc, collection, doc, getDocs, increment, runTransaction, serverTimestamp, updateDoc } from "firebase/firestore";
import { getDownloadURL, getMetadata, getStorage, ref, uploadBytes ,ref as storageRef} from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";


export default function Courses(){
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [uploadedFiles1, setUploadedFiles1] = useState([]);
    const [uploadedVideo1, setUploadedVideo1] = useState(null);
    const [difficultyLevel, setDifficultyLevel] = useState("");
    const [fee, setFee] = useState("");
    const [freeCourseChecked, setFreeCourseChecked] = useState(false);
    const [featuredCourseChecked, setFeaturedCourseChecked] = useState(false);
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [lessonVideoDuration, setLessonVideoDuration] = useState("");
    const [showCourseSection, setShowCourseSection] = useState(true);
    const [showLessonSection, setShowLessonSection] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [lessonFieldsCleared, setLessonFieldsCleared] = useState(false);
    const [dataSaved, setDataSaved] = useState(false);
    const [correctAnswer, setCorrectAnswer] = useState("");
    const navigate = useNavigate();
    const {uid} = useParams();
    const [questions, setQuestions] = useState([
        {
          id: 1,
          question: '',
          options: ['', '', '', ''],
          correctAnswer: '',
        },
      ]);


const generateUniqueId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        question: '',
        options: ['', '', '', ''],
        correctAnswer: '',
      },
    ]);
  };
  const handleInputChange = (index, type, value) => {
    const newQuestions = [...questions];
    if (type === 'question') {
      newQuestions[index].question = value;
    } else {
      newQuestions[index].options[type] = value;
    }
    setQuestions(newQuestions);
  };

const getVideoDuration = async (videoFile) => {
  return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          const durationInSeconds = Math.floor(video.duration);
          resolve(durationInSeconds);
      };
      video.onerror = reject;
      video.src = URL.createObjectURL(videoFile);
  });
};

const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } = useDropzone({
    accept: "video/mp4,video/webm,video/ogg",
    onDrop: useCallback(acceptedFiles => {
        const videoFiles = acceptedFiles.filter(file => file.type.startsWith('video/'));
        if (videoFiles.length === 0) {
            alert("Please select a video file.");
            return;
        }
        setUploadedVideo(videoFiles[0]);
    }, [])
});







    const { getRootProps: getMainImageRootProps, getInputProps: getMainImageInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setUploadedFiles(acceptedFiles);
        },
    });

    const { getRootProps: getVideoRootProps1, getInputProps: getVideoInputProps1 } = useDropzone({
        accept: "video/mp4,video/webm,video/ogg",
        onDrop: useCallback(acceptedFiles => {
            const videoFiles = acceptedFiles.filter(file => file.type.startsWith('video/'));
            if (videoFiles.length === 0) {
                alert("Please select a video file.");
                return;
            }
            setUploadedVideo1(videoFiles[0]);
        }, [])
    });


  const { getRootProps: getMainImageRootProps1, getInputProps: getMainImageInputProps1 } = useDropzone({
      onDrop: (acceptedFiles) => {
          setUploadedFiles1(acceptedFiles);
      },
  });

    const handleDifficultyLevelChange = (event) => {
        setDifficultyLevel(event.target.value);
    };

    const handleFeeChange = (event) => {
        const value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        setFee(value);
    };
    const handleFreeCourseChange = () => {
      setFreeCourseChecked(!freeCourseChecked); // Toggle the state
      setFeaturedCourseChecked(false); // Ensure the other checkbox is unchecked
  };
  
  const handleFeaturedCourseChange = () => {
      setFeaturedCourseChecked(!featuredCourseChecked); // Toggle the state
      setFreeCourseChecked(false); // Ensure the other checkbox is unchecked
  };

useEffect(() => {
    setSavingData(false);
        checkAllFieldsFilled();
    }, [uploadedFiles, uploadedVideo, difficultyLevel, fee]);

const checkAllFieldsFilled = () => {
  if (
      uploadedFiles.length > 0 &&
      uploadedVideo &&
      difficultyLevel &&
      fee !== "" &&
      document.getElementById('courseCategory').value.trim() !== "" &&
      document.getElementById('keyPoints').value.trim() !== "" &&
      document.getElementById('courseTitle').value.trim() !== "" &&
      document.getElementById('courseDescription').value.trim() !== "" 
     
  ) {
      setAllFieldsFilled(true);
  } else {
      setAllFieldsFilled(false);
  }
};

const saveCourseToTestCollection = async () => {
    try {
        const keyPointsText = document.getElementById('keyPoints').value;
        const keyPointsArray = keyPointsText.split('\n').filter(point => point.trim() !== '');

        const courseImageFile = uploadedFiles[0];
        const courseImageStorageRef = ref(storage, `course_images/${courseImageFile.name}`);
        await uploadBytes(courseImageStorageRef, courseImageFile);

        const courseVideoFile = uploadedVideo;
        const courseVideoStorageRef = ref(storage, `course_videos/${courseVideoFile.name}`);
        await uploadBytes(courseVideoStorageRef, courseVideoFile);

        const courseImageUrl = await getDownloadURL(courseImageStorageRef);
        const courseVideoUrl = await getDownloadURL(courseVideoStorageRef);
        const feeNumber = parseFloat(fee);

        const courseData = {
            category: document.getElementById('courseCategory').value,
            keyPoints: keyPointsArray,
            courseTitle: document.getElementById('courseTitle').value,
            courseDescription: document.getElementById('courseDescription').value,
            feeInDollar: feeNumber,
            level: difficultyLevel,
            courseCode: document.getElementById('courseCode').value,
            discount: document.getElementById('discount').value,
            courseImage: courseImageUrl,
            courseIntroVideoURL: courseVideoUrl,
            totalLessons: 1,
            ratings: 0,
            reviews: 0,
            duration: "80min",
            usersEnrolled: 0,
            mentorId: "nvOmLIm9v1p9pTtYIljt",
            dateAdded: new Date(),
            noFee: !featuredCourseChecked, 
            isFeatured: featuredCourseChecked,
            type:"course"
        }; 

        const courseRef = await addDoc(collection(firestore, "courses"), courseData);
        const courseId = courseRef.id; // Get the generated courseId

        // Update the courses collection with courseId
        await updateDoc(doc(firestore, "courses", courseId), {
            courseId: courseId // Adding courseId in courses collection
        });

        return courseId;
    } catch (error) {
        console.error("Error adding course data: ", error);
        throw error;
    }
};

const saveLessonToCourseSubcollection = async (courseUid) => {
    try {
      const lessonImageFile = uploadedFiles1[0];
      const lessonImageStorageRef = ref(storage, `lesson_images/${lessonImageFile.name}`);
      await uploadBytes(lessonImageStorageRef, lessonImageFile);

      const lessonVideoFile = uploadedVideo1;
      const lessonVideoStorageRef = ref(storage, `lesson_videos/${lessonVideoFile.name}`);
      await uploadBytes(lessonVideoStorageRef, lessonVideoFile);

      const lessonImageUrl = await getDownloadURL(lessonImageStorageRef);
      const lessonVideoUrl = await getDownloadURL(lessonVideoStorageRef);
      const lessonVideoDuration = await getVideoDuration(uploadedVideo1);

      const seconds = await getVideoDuration(uploadedVideo1);
      const duration = seconds >= 60 ? Math.floor(seconds / 60) + " minutes" : seconds + " seconds";

      const videoId = generateUniqueId();

      const lessonData = {
        courseId: courseUid,
        lessonName: document.getElementById('lessonName').value,
        lessonNo: document.getElementById('lessonno').value,
        type: "Lesson",
        lessonDate: new Date(),
        quiz: questions.map((q) => ({
          question: q.question,
          options: q.options,
          answer: q.correctAnswer,
        })),
        lectures: [{
          videoUrl: lessonVideoUrl,
          videoLength: duration,
          videoId: videoId,
          videoTitle: document.getElementById('title').value,
        }],
        lessonImage: lessonImageUrl,
        totalLectures: 0,
      };

      const lessonRef = await addDoc(collection(firestore, "courses", courseUid, "lessons"), lessonData);
      const lessonId = lessonRef.id;

      await updateDoc(doc(firestore, "courses", courseUid, "lessons", lessonRef.id), {
        lessonId: lessonId,
      });

      await updateDoc(doc(firestore, "courses", courseUid, "lessons", lessonRef.id), {
        lecturesLength: increment(1),
      });

      return lessonRef.id;
    } catch (error) {
      console.error("Error adding lesson data: ", error);
      throw error;
    }
  };
useEffect(() => {
    setSavingData(false);
    checkAllFieldsFilled();
}, [uploadedFiles, uploadedVideo, difficultyLevel, fee]);
useEffect(() => {
    if (uid) {
        setShowCourseSection(false);
        setShowLessonSection(true);
    }
}, [uid]);

const handleSaveCourseButton = async () => {
    try {
        setSavingData(true);
        const courseUid = await saveCourseToTestCollection();

        // Store courseUid in localStorage if uid exists
        if (uid) {
            localStorage.setItem('courseUid', courseUid);
            setShowCourseSection(false);
            setShowLessonSection(true);
        }

        await saveLessonToCourseSubcollection(courseUid);
        console.log("Lesson data saved successfully!");
        setSavingData(false);
        toast.success('Add Lessons in new Course');
    } catch (error) {
        console.error("Error saving course data: ", error);
        setSavingData(false);
    }
};

// When saving the lesson
const handleSaveLessonButton = async () => {
    try {
        setSavingData(true);
        const courseUid = localStorage.getItem('courseUid');
        if (!courseUid) {
            throw new Error("Course UID not found in localStorage.");
        }

        await saveLessonToCourseSubcollection(courseUid);
        console.log("Lesson data saved successfully!");
        resetLessonFields();
        setLessonFieldsCleared(true);
        setSavingData(false);
        setUploadedFiles1([]);
        setUploadedVideo1(null);
        setDataSaved(true);
        toast.success('Lesson saved successfully');
    } catch (error) {
        console.error("Error saving lesson data: ", error);
        setSavingData(false);
        toast.error('Error saving lesson data');
    }
};
const resetLessonFields = () => {
    // Logic to reset lesson fields...
    document.getElementById('lessonName').value = "";
    document.getElementById('title').value = "";
    document.getElementById('question').value = "";
    document.getElementById('option1').value = "";
    document.getElementById('option2').value = "";
    document.getElementById('option3').value = "";
    document.getElementById('lessonno').value = "";
    document.getElementById('option4').value = "";
  
};
const handleCheckboxChange = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].correctAnswer = newQuestions[questionIndex].options[optionIndex];
    setQuestions(newQuestions);
  };

    return(
        <>
        <ToastContainer position="top-right" />
         {savingData && <Loader />}
        
            <div className="course-main">
                <div className="course">
                  
                    {showCourseSection && (
                    <div className="coursesss">
                          <div className="course-header">
                        <div className="container">
                            <h3>
                                Add courses
                            </h3>
                        </div>
                    </div>
                        <div className="drop-main">
                       {/* Main Image Picker */}
<div className="pickers">
    <div className="drag-main">
        <div className="drag-drop">
            <div {...getMainImageRootProps()} className="dropzone-container">
                <input {...getMainImageInputProps()} accept="image/*" />
                {uploadedFiles.length === 0 ? (
                    <p className="drag-drop-text">
                        <img src="image.png" alt="" /> Select or Drop the Image here
                    </p>
                ) : null}
                {uploadedFiles.map((file) => (
                    <div key={file.name} className="drop-img">
                        <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="uploaded-image"
                        />
                    </div>
                ))}
            </div>
        </div>
    </div>
    <div className="drag-main">
        <div className="drag-drop">
            <div {...getVideoRootProps()} className="dropzone-container">
            <input {...getVideoInputProps()} />
                {!uploadedVideo ? (
                    <p className="drag-drop-text">
                        <img src="upload.png" alt="" /> Select or Drop the Video here
                    </p>
                ) : (
                    <div className="drop-img">
                        <video className="uploaded-video" controls>
                            <source src={URL.createObjectURL(uploadedVideo)} type={uploadedVideo.type} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
            </div>
        </div>
    </div>
</div>

                        {/* Profile Image Picker */}
                        <div className="course-inputs-main">
                            <div className="course-inputs">
                                <TextField
                                    id="courseCategory"
                                    label="Course Category"
                                    multiline
                                    maxRows={4}
                                    variant='filled'
                                    className="filled-multiline-flexible"
                                   
                                />
                 <TextField
    id="keyPoints"
    label="Key Points"
    multiline
    maxRows={4}
    variant='filled'
    className="filled-multiline-flexible"
    onKeyDown={(e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const textField = e.target;
            const currentValue = textField.value;
            textField.value = currentValue + '\n'; // Add a new line
        }
    }}
/>

                            </div>
                            <div className="course-inputs">
                                <TextField
                                    id="courseTitle"
                                    label="Course Title"
                                    multiline
                                    maxRows={4}
                                    variant='filled'
                                    className="filled-multiline-flexible"
                                />
                                <TextField
                                    id="courseDescription"
                                    label="Course Description"
                                    multiline
                                    maxRows={4}
                                    variant='filled'
                                    className="filled-multiline-flexible"
                                />
                            </div>
                            <div className="course-inputs">
                                <TextField
                                    id="fee"
                                    label="Fee"
                                    multiline
                                    maxRows={4}
                                    variant='filled'
                                    className="filled-multiline-flexible"
                                    value={fee}
                                    onChange={handleFeeChange}
                                />
                                <FormControl className="filled-multiline-flexible">
                                    {difficultyLevel === "" && (
                                        <InputLabel id="difficulty-level-label">Difficulty Level</InputLabel>
                                    )}
                                    <Select
                                        id="difficultyLevel"
                                        value={difficultyLevel}
                                        variant='filled'
                                        onChange={handleDifficultyLevelChange}
                                    >
                                        <MenuItem value="First">First</MenuItem>
                                        <MenuItem value="Second">Second</MenuItem>
                                        <MenuItem value="Third">Third</MenuItem>
                                        <MenuItem value="Forth">Forth</MenuItem>
                                        <MenuItem value="Fifth">Fifth</MenuItem>
                                        <MenuItem value="Dentist">Dentist</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="course-inputs">
                                <TextField
                                    id="courseCode"
                                    label="Course Code"
                                    multiline
                                    maxRows={4}
                                    variant='filled'
                                    className="filled-multiline-flexible"
                                />
                                <TextField
                                    id="discount"
                                    label="Discount"
                                    multiline
                                    maxRows={4}
                                    variant='filled'
                                    className="filled-multiline-flexible"
                                />
                            </div>
                            <div className="course-inputs">
 
    <div className="checkbox-wrapper-33">
        <label className="checkbox">
            <input
                className="checkbox__trigger visuallyhidden"
                type="checkbox"
                checked={featuredCourseChecked}
                onChange={handleFeaturedCourseChange}
            />
            <span className="checkbox__symbol">
                <svg
                    aria-hidden="true"
                    className="icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 14l8 7L24 7"></path>
                </svg>
            </span>
            <p className="checkbox__textwrapper">Featured Course</p>
        </label>
    </div>
</div>
                        </div>
                    </div>
                    <div className="save-button">
                    <button className="submit"onClick={handleSaveCourseButton} >Next</button>
                       
                    </div>
                    </div>
                     )}

                     {showLessonSection && (
                    <div id="lessons-section" className="lessons" >
                        <div className="course-header">
    <div className="container">
        <h3>
                        {dataSaved ? "Add More Lessons" : "Add Lesson"}
        </h3>
       
    </div>
</div>

    <div className="lessons">
      
        {/* Main Image Picker */}
        <div className="drop-main">
            <div className="pickers">
                <div className="drag-main">
                    <div className="drag-drop">
                        <div {...getMainImageRootProps1()} className="dropzone-container">
                            <input {...getMainImageInputProps1()} />
                            {uploadedFiles1.length === 0 ? (
                                <p className="drag-drop-text">
                                    <img src="image.png" alt="" /> Select or Drop the Image here
                                </p>
                            ) : null}
                            {uploadedFiles1.map((file) => (
                                <div key={file.name} className="drop-img">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        className="uploaded-image"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="drag-main">
                    <div className="drag-drop">
                        <div {...getVideoRootProps1()} className="dropzone-container">
                            <input {...getVideoInputProps1()} />
                            {!uploadedVideo1 ? (
                                <p className="drag-drop-text">
                                    <img src="upload.png" alt="" /> Select or Drop the Video here
                                </p>
                            ) : (
                                <div className="drop-img">
                                    <video className="uploaded-video" controls>
                                        <source src={URL.createObjectURL(uploadedVideo1)} type={uploadedVideo1.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Profile Image Picker */}
        <div className="course-inputs-main">
            <div className="course-inputs">
                <TextField
                    id="lessonName"
                    label="Lesson Name"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                />
                 <TextField
                    id="title"
                    label="Video Title"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                />
            </div>
            <div className="course-inputs">
               
                 <TextField
                    id="lessonno"
                    label="Lesson No"
                    multiline
                    maxRows={4}
                    variant='filled'
                    className="filled-multiline-flexible"
                />
            </div>
            <div className="course-inputs-main">
      {questions.map((q, index) => (
        <div key={q.id} className="course-inputs-main">
          <div className="course-inputs">
          <TextField
            label={`Question ${index + 1}`}
            multiline
            maxRows={4}
            variant="filled"
            className="filled-multiline-flexible1"
            value={q.question}
            onChange={(e) => handleInputChange(index, 'question', e.target.value)}
          />
          <Tooltip title="Add Questions">
          <IconButton onClick={handleAddQuestion} sx={{
            backgroundColor:"rgba(1, 203, 196, .507)",
            height:"60px",
            width:"50px",
           }}>
        <Add sx={{
            color:"white"
        }} />
      </IconButton>
          </Tooltip>
          </div>
          {q.options.map((option, optionIndex) => (
            <div key={optionIndex} className="course-inputss">
              <div className="checkbox-wrapper-33">
                <label className="checkbox">
                  <input
                    className="checkbox__trigger visuallyhidden"
                    type="radio"
                    name={`option-${q.id}`}
                    onChange={() => handleCheckboxChange(index, optionIndex)}
                  />
                  <span className="checkbox__symbol">
                    <svg
                      aria-hidden="true"
                      className="icon-checkbox"
                      width="28px"
                      height="28px"
                      viewBox="0 0 28 28"
                      version="1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 14l8 7L24 7"></path>
                    </svg>
                  </span>
                </label>
              </div>
              <TextField
                label={`Option ${optionIndex + 1}`}
                multiline
                maxRows={4}
                variant="filled"
                className="filled-multiline-flexible"
                value={option}
                onChange={(e) => handleInputChange(index, optionIndex, e.target.value)}
              />
            </div>
          ))}
        </div>
      ))}
     
    </div>
           
        </div>
        <div className="save-button">
            <button className="submit" onClick={handleSaveLessonButton}>Submit Lesson</button>
           
        </div>
    </div>
</div> )}

                </div>
            </div>
        </>
    )
}
const Loader = () => {
    return (
      <div className="overlay">
 <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{'--index': index}} key={index}></div>
                    ))}
                </div>
            </div>
      </div>
    );
};
