import { useEffect, useRef, useState } from 'react';
import { firestore } from '../firebase'; // Import firestore from your firebase setup file
import "../chat/chat.css";
import { collection, getDocs, query, doc, getDoc, orderBy, limit, Timestamp, addDoc, onSnapshot, deleteDoc } from 'firebase/firestore';

export default function Chat() {
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [usersData, setUsersData] = useState({});
  const [chats, setChats] = useState([]);
  const messagesEndRef = useRef(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuChatId, setContextMenuChatId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    
    const fetchChats = async () => {
      const messagesCollection = collection(firestore, 'admin_messages');
      const messagesSnapshot = await getDocs(messagesCollection);
      const chatsData = [];
    
      for (const doc of messagesSnapshot.docs) {
        const messageSubCollectionRef = collection(doc.ref, 'messages');
        const messageSubCollectionSnapshot = await getDocs(query(messageSubCollectionRef, orderBy('timestamp', 'desc'), limit(1)));
        const latestMessage = messageSubCollectionSnapshot.docs[0]?.data();
    
        if (latestMessage) {
          const timestamp = latestMessage.timestamp.toDate();
          const formattedTime = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          const formattedDate = timestamp.toLocaleDateString();
    
          chatsData.push({
            id: doc.id,
            lastMessage: latestMessage.message,
            time: timestamp,
            formattedTime,
            formattedDate
          });
        }
      }
    
      // Sort chatsData based on the timestamp property
      chatsData.sort((a, b) => b.time - a.time);
    
      setChats(chatsData);
      setSavingData(false);
      setLoading(false);
    };
    

    const unsubscribe = onSnapshot(collection(firestore, 'admin_messages'), () => {
      fetchChats(); // Update chat inbox when a new chat is added or existing chat is updated
    });

    return unsubscribe;
  }, []);

  const handleScrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  };
  
  useEffect(() => {
    handleScrollToBottom();
  }, [messages]);

  const handleChatClick = async (chatId) => {
    setSelectedChat(chatId);
    const messageSubCollectionRef = collection(doc(firestore, 'admin_messages', chatId), 'messages');
    const messageSubCollectionSnapshot = await getDocs(query(messageSubCollectionRef, orderBy('timestamp', 'asc')));
    const fetchedMessages = messageSubCollectionSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      time: doc.data().timestamp.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }));
    setMessages(fetchedMessages);
  };
  const handleSendMessage = async () => {
    if (newMessage.trim() === '' || !selectedChat) return;

    const messageRef = collection(firestore, 'admin_messages', selectedChat, 'messages');
    const usermessageRef = collection(firestore, 'admin_messages', selectedChat, 'usermessages');
    const newMessageObj = {
        message: newMessage,
        timestamp: new Date(),
    };

    try {
        // Add message to 'messages' subcollection
        await addDoc(messageRef, newMessageObj);
        
        // Add message to 'usermessages' subcollection
        await addDoc(usermessageRef, newMessageObj);

        setNewMessage(''); // Clear input after sending message
    } catch (error) {
        console.error('Error sending message:', error);
    }
};

  
  const handleDeleteChat = async (chatId) => {
    const messageSubCollectionRef = collection(firestore, 'admin_messages', chatId, 'messages');
    const messageSubCollectionSnapshot = await getDocs(messageSubCollectionRef);
    messageSubCollectionSnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
      setNewMessage('');
    }
  };
  useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(firestore, 'admin_messages'), orderBy('timestamp', 'desc')), (snapshot) => {
      const chatsData = snapshot.docs.map(doc => ({
        id: doc.id,
        lastMessage: doc.data().lastMessage,
        time: doc.data().timestamp.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }));
      setChats(chatsData);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    // Real-time listener for messages
    const unsubscribe = selectedChat ? onSnapshot(query(collection(firestore, 'admin_messages', selectedChat, 'messages'), orderBy('timestamp', 'asc')), (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        time: doc.data().timestamp.toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }));
      setMessages(fetchedMessages);
    }) : () => {};
  
    return unsubscribe;
  }, [selectedChat]);

  useEffect(() => {
    // Fetch user data for all chats
    const fetchUserData = async () => {
      const userData = {};
      for (const chat of chats) {
        const docRef = doc(firestore, 'userDetails', chat.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          userData[chat.id] = { ...docSnap.data(), id: docSnap.id }; // Include user id in userData
        }
      }
      setUsersData(userData);
      setSavingData(false);
    };

    fetchUserData();
  }, [chats]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showContextMenu && !event.target.closest('.context-menu')) {
        setShowContextMenu(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showContextMenu]);

  const handleContextMenu = (event, chatId) => {
    event.preventDefault();
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setShowContextMenu(true);
    setContextMenuChatId(chatId);
  };

  const handleContextMenuDelete = () => {
    handleDeleteChat(contextMenuChatId);
    setShowContextMenu(false);
  };
  

  return (
    <>

      <div className="main-chat">
        {loading ? ( // Conditionally render shimmer effect or chat inbox based on loading state
          <div className="shimmer-placeholder">
            {/* Shimmer effect placeholder */}
          </div>
        ) : (
        
        <div className="chat-inbox">
    {chats.map((chat) => (
      
  <div 
    className={`chat-item ${selectedChat === chat.id ? 'active' : ''}`} 
    key={chat.id} 
    onClick={() => handleChatClick(chat.id)}
    onContextMenu={(e) => handleContextMenu(e, chat.id)}
  >
                 {savingData && <Loader />}

    <div className="profile-img">
      <img src={usersData[chat.id]?.profilePic} alt={chat.username} />
    </div>
    <div className="message-info">
      {usersData[chat.id] ? ( // Check if user data is available
        <div className="username">{usersData[chat.id].username.charAt(0).toUpperCase() + usersData[chat.id].username.slice(1)}</div>
      ) : (
        <div className="username-loader">
          <Loader /> {/* Display loader until user data is fetched */}
        </div>
      )}
      <div className="last-message">
        {chat.lastMessage && chat.lastMessage.length > 30 ? chat.lastMessage.substring(0, 30) + '...' : chat.lastMessage}
      </div>
      {/* Truncate last message to 30 characters and add ellipsis if longer */}
    </div>
    <div className="time">{chat.formattedTime}</div> {/* Use formattedTime */}
  </div>
))}



        </div>
        )}
      </div>
      {selectedChat ? (
        <div className="chat-window">
          <div className="chat-header">
            <div className="profile-img">
              <img src={usersData[selectedChat]?.profilePic} alt={usersData[selectedChat]?.username} />
            </div>
            <div className="username">{usersData[selectedChat]?.username}</div>
          </div>
          <div className="messages">
            {messages.map(message => (
              <div key={message.id} className={`message ${message.UserId === usersData[selectedChat].id ? 'received' : 'sent'}`}>
                <div className="message-text">{message.message}</div>
                <div className="message-time">{message.time}</div>
              </div>
            ))}
             <div ref={messagesEndRef}  />
          </div>
          <div className="chat-input">
            <input type="text" placeholder="Type your message..." value={newMessage} onChange={(e) => setNewMessage(e.target.value)} onKeyPress={handleKeyPress} />
            <button onClick={handleSendMessage}><img src="send.png" alt="" /></button>
          </div>
        </div>
      ) : (
        <div className="no-chat-selected-message"><img src="https://cdn-icons-gif.flaticon.com/12134/12134182.gif" alt="" /><p>No Chat Selected</p></div>
      )}
      {showContextMenu && (
        <div className="context-menu" style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}>
          <div className="context-menu-item" onClick={handleContextMenuDelete}>Clear Chat</div>
        </div>
      )}
    </>
  );
}

const Loader = () => {
  return (
    <div className="overlay">
<div className="loader-main">
              <div className="loader">
                  {[...Array(5)].map((_, index) => (
                      <div className="orbe" style={{'--index': index}} key={index}></div>
                  ))}
              </div>
          </div>
    </div>
  );
};
