import { useState } from "react";
import { firestore } from "../firebase"; // Assuming you have firebase configured
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { Timestamp, addDoc, collection, doc, getDocs, onSnapshot, query, updateDoc, where, writeBatch } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit, FileCopy, UploadFile } from "@mui/icons-material";
import '../vouchers/vouchers.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import "./vouchers.css";


const columns = [
    { id: 'voucherName', label: 'Voucher Name', minWidth: 170, align: 'center' }, // Add this line for voucher name
    { id: 'title', label: 'Vouchers quantity', minWidth: 170, align: 'center' },
    { id: 'lessons', label: 'Points', minWidth: 100, align: 'center' },
    { id: 'action', label: 'Action', minWidth: 170, align: 'center' },
];

export default function Vouchers() {
    const [value, onChange] = useState(new Date());
    const [discountType, setDiscountType] = useState("");
    const [voucherName, setVoucherName] = useState("");
    const [points, setPoints] = useState("");
    const [usageLimit, setUsageLimit] = useState("");
    const [voucherCode, setVoucherCode] = useState("");
    const [startingDate, setStartingDate] = useState(new Date());
    const [validityEnd, setExpiryDate] = useState(new Date());
    const [error, setError] = useState("");
    const [savingData, setSavingData] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [proPrice, setProPrice] = useState('');
    const [premiumPrice, setPremiumPrice] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editedDescription, setEditedDescription] = useState("");
    const [editedValidityStart, setEditedValidityStart] = useState(null);
    const [editedValidityEnd, setEditedValidityEnd] = useState(null);
    const [bulkVoucherMode, setBulkVoucherMode] = useState(true);
    const [bulkQuantity, setBulkQuantity] = useState(0);
    const [bulkId, setBulkId] = useState('');
    const [deleteId, setDeleteId] = useState(null); 

    const handleBulkVoucherToggle = () => {
        // Toggle bulk voucher mode
        setBulkVoucherMode(!bulkVoucherMode);
        // Generate a new bulk ID when toggling
        if (!bulkVoucherMode) {
            const newBulkId = generateUniqueBulkId();
            setBulkId(newBulkId);
        }
    };
    const handleBulkQuantityChange = (e) => {
        setBulkQuantity(e.target.value);
    };
    const handleSaveVoucher = () => {
        // Validate form fields
        if (!voucherName || !discountType || !points || !usageLimit || !voucherCode || !startingDate || !validityEnd) {
            setError("All fields are required");
            toast.error('All fields are required');
            return;
        }

        setSavingData(true);

        const pointsValue = parseFloat(points);
        const usageLimitValue = parseInt(usageLimit);

        // If in bulk voucher mode, generate and save multiple vouchers
        if (bulkVoucherMode) {
            const newBulkId = generateUniqueBulkId();
            const vouchers = [];
            for (let i = 0; i < bulkQuantity; i++) {
                const newVoucherCode = generateUniqueVoucherCode(6);
                vouchers.push({
                    voucherName,
                    discountType,
                    points:pointsValue,
                    usageLimit: usageLimitValue,
                    voucherCode: newVoucherCode,
                    startingDate,
                    validityEnd,
                    createdBy: "admin",
                    bulkId:newBulkId,
                    redeemed:false,
                });
            }
            // Save vouchers to Firestore
            Promise.all(vouchers.map((voucher) => addDoc(collection(firestore, "vouchers"), voucher)))
                .then(() => {
                    console.log("Bulk vouchers added successfully!");
                    toast.success('Bulk Vouchers Successfully Added');
                    setVoucherName("");
                    setDiscountType("");
                    setPoints("");
                    setUsageLimit("");
                    setVoucherCode("");
                    setStartingDate(new Date());
                    setExpiryDate(new Date());
                    setError("");
                })
                .catch((error) => {
                    console.error("Error adding bulk vouchers: ", error);
                    setError("Error adding bulk vouchers. Please try again later.");
                })
                .finally(() => {
                    setSavingData(false);
                });
        } else {
            // Single voucher saving logic
            const voucherData = {
                voucherName,
                discountType,
                points: pointsValue,
                usageLimit: usageLimitValue,
                voucherCode,
                startingDate,
                validityEnd,
                createdBy: "admin",
            };

            // Add voucher data to Firestore
            addDoc(collection(firestore, "vouchers"), voucherData)
                .then(() => {
                    console.log("Voucher added successfully!");
                    toast.success('Voucher Successfully Added');
                    voucherName("");
                    setDiscountType("");
                    setPoints("");
                    setUsageLimit("");
                    setVoucherCode("");
                    setStartingDate(new Date());
                    setExpiryDate(new Date());
                    setError("");
                })
                .catch((error) => {
                    console.error("Error adding voucher: ", error);
                    setError("Error adding voucher. Please try again later.");
                })
                .finally(() => {
                    setSavingData(false);
                });
        }
    };
    const generateUniqueVoucherCode = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    const generateUniqueBulkId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    useEffect(() => {
        const fetchData = () => {
            try {
                const unsubscribe = onSnapshot(collection(firestore, 'vouchers'), (snapshot) => {
                    const fetchedData = {};
    
                    snapshot.forEach((doc) => {
                        const clinicData = doc.data();
                        const bulkId = clinicData.bulkId;
    
                        if (!fetchedData[bulkId]) {
                            fetchedData[bulkId] = {
                                voucherName: clinicData.voucherName,
                                usageLimit: clinicData.usageLimit,
                                points: clinicData.points,
                                quantity: 1, 
                                bulkId: bulkId,
                            };
                        } else {
                            // Increment quantity for existing bulkId
                            fetchedData[bulkId].quantity++;
                        }
                    });
    
                    setData(Object.values(fetchedData));
                });
    
                return unsubscribe;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        const unsubscribe = fetchData();
        return () => unsubscribe();
    }, []);
    
  


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filteredData = data.filter((row) =>
        Object.values(row).some(
            (value) => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );
    const formatDateTimeLocal = (date) => {
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hours = `${date.getHours()}`.padStart(2, '0');
        const minutes = `${date.getMinutes()}`.padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
   const handleDelete = (bulkId) => {
        // Show dialog box for confirmation
        setOpenDialog(true);
        setDeleteId(bulkId); // Set the ID to delete when confirmed
    };

    const handleDeleteConfirmed = async () => {
        try {
            const batch = writeBatch(firestore);
            const querySnapshot = await getDocs(query(collection(firestore, 'vouchers'), where('bulkId', '==', deleteId)));
            querySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
            });
            await batch.commit();
            toast.success("Documents successfully deleted");
        } catch (error) {
            console.error("Error deleting documents:", error);
            toast.error("Error deleting documents. Please try again later.");
        } finally {
            setOpenDialog(false);
            setDeleteId(null);
        }
    };

    const saveBulkToExcel = async (bulkId) => {
        try {
            // Query Firestore to get all documents with the specified bulkId
            const querySnapshot = await getDocs(query(collection(firestore, 'vouchers'), where('bulkId', '==', bulkId)));
            
            // Extract the data from the querySnapshot
            const vouchers = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                // Convert Firestore timestamps to Date objects
                const startDate = data.startingDate ? data.startingDate.toDate() : null;
                const endDate = data.validityEnd ? data.validityEnd.toDate() : null;
                // Add the converted dates to the data object
                vouchers.push({
                    ...data,
                    startingDate: startDate ? startDate.toLocaleDateString() : 'N/A',
                    validityEnd: endDate ? endDate.toLocaleDateString() : 'N/A',
                });
            });
    
            if (vouchers.length === 0) {
                toast.error('No vouchers found for this bulkId');
                return;
            }
    
            // Convert the data to an Excel sheet
            const worksheet = XLSX.utils.json_to_sheet(vouchers);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Vouchers');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            
            // Create a Blob from the Excel buffer
            const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
            const fileName = `vouchers_${bulkId}.xlsx`;
            
            // Save the file
            saveAs(data, fileName);
            
            toast.success(`File saved as ${fileName}. Please open it manually.`);
        } catch (error) {
            console.error('Error fetching vouchers: ', error);
            toast.error('Error fetching vouchers. Please try again later.');
        }
    };
    
    
    
    
    
    
    return (
        <>
            <ToastContainer position="top-right" />
            {savingData && <Loader />}
            <div className="course-main">
                <div className="course">
                    <div className="course-header">
                        <div className="container">
                            <h3>Add Voucher</h3>
                        </div>
                    </div>
                    <div className="main-input-search">
                        <div className="checkbox-wrapper-4">
                            <input
                                className="inp-cbx"
                                id="bulkVoucherToggle"
                                type="checkbox"
                                checked={bulkVoucherMode}
                                onChange={handleBulkVoucherToggle}
                            />
                            <label className="cbx" htmlFor="bulkVoucherToggle">
                                <span> <svg id="svg" width="12px" height="10px"></svg></span>
                                <span>Bulk Voucher Mode</span>
                            </label>
                        </div>
                    </div>
                    <div className="course-inputs-main">
                        <div className="course-inputs">

                            <TextField
                                id="voucherName"
                                label="Voucher Name"
                                multiline
                                maxRows={4}
                                variant='filled'
                                className="filled-multiline-flexible"
                                value={voucherName}
                                onChange={(e) => setVoucherName(e.target.value)}
                            />
                            <FormControl className="filled-multiline-flexible">
                                {discountType === "" && (
                                    <InputLabel id="difficulty-level-label">Discount Type</InputLabel>
                                )}
                                <Select
                                    id="discountType"
                                    variant='filled'
                                    value={discountType}
                                    onChange={(e) => setDiscountType(e.target.value)}
                                >
                                    <MenuItem value="Fixed">Fixed</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="course-inputs">
                            <TextField
                                id="points"
                                label="Points"
                                type="number" // Set type to number
                                variant='filled'
                                className="filled-multiline-flexible"
                                value={points}
                                onChange={(e) => setPoints(e.target.value)}
                            />
                            <TextField
                                id="usageLimit"
                                label="Usage Limit"
                                type="number" // Set type to number
                                variant='filled'
                                className="filled-multiline-flexible"
                                value={usageLimit}
                                onChange={(e) => setUsageLimit(e.target.value)}
                            />
                        </div>
                        <div className="course-inputs">
                            <TextField
                                id="vouchercode"
                                label="Voucher Code"
                                multiline
                                maxRows={4}
                                variant='filled'
                                className="filled-multiline-flexible"
                                value={voucherCode}
                                onChange={(e) => setVoucherCode(e.target.value)}
                            />


                            {bulkVoucherMode && (
                                <TextField
                                    id="bulkQuantity"
                                    label="Quantity"
                                    type="number"
                                    variant="filled"
                                    className="filled-multiline-flexible"
                                    value={bulkQuantity}
                                    onChange={handleBulkQuantityChange}
                                />
                            )}
                        </div>
                        <div className="date-inputs" style={{ width: "60%" }}>
                            <div className="date-pickers">
                                <p>Starting Date</p>
                                <DateTimePicker onChange={setStartingDate} value={startingDate} />
                            </div>
                            <div className="date-pickers">
                                <p>Expiry Date</p>
                                <DateTimePicker onChange={setExpiryDate} value={validityEnd} />
                            </div>

                        </div>
                    </div>
                    <div className="save-button" style={{ marginTop: "60px" }}>
                        <button className="submit" onClick={handleSaveVoucher}>Save Voucher</button>
                    </div>

                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px' }}>
                        <TableContainer sx={{ maxHeight: "55vh" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth, fontWeight: 'bolder', fontSize: "21px",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
    {filteredData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} >
                {columns.map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align} style={column.id === 'img' ? { fontWeight: 'bold', fontSize: '17px' } : {}}>
                            {column.id === 'action' ? (
                                <div>
                                    <Tooltip title="Change Price">
                                    <IconButton onClick={() => handleDelete(row.bulkId)} style={{ backgroundColor: "red",marginRight:"15px" }}>
                                    <Delete style={{ color: "white" }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save to Excel">
                                    <IconButton style={{ backgroundColor: "#17C3B2" }} onClick={() => saveBulkToExcel(row.bulkId)}>
                                    <UploadFile style={{ color: "white" }} />
        </IconButton>
                                    </Tooltip>
                                </div>
                            ) : (
                                column.id === 'title' ? (row.quantity) : (column.id === 'lessons' ? row.points : value)
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        ))}
</TableBody>

                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      

                    </Paper>
                </div>
            </div>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete all documents with this ID?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirmed} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
const Loader = () => {
    return (
        <div className="overlay">
            <div className="loader-main">
                <div className="loader">
                    {[...Array(5)].map((_, index) => (
                        <div className="orbe" style={{ '--index': index }} key={index}></div>
                    ))}
                </div>
            </div>
        </div>
    );
};
