import React from "react";

const EditIcon = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="20"
      height="20"
      viewBox="0 0 19 19"
      xmlSpace="preserve"
    >
      <path
        d="M16.1753 4.33289L14.0112 2.16881C13.8849 2.04251 13.7067 1.97462 13.5203 1.97462C13.3338 1.97462 13.1556 2.04251 13.0293 2.16881L11.0225 4.17568L14.8255 8.01191L16.8324 5.00508C16.9587 4.87878 17.0266 4.70058 17.0266 4.51412C17.0266 4.32766 16.9587 4.14946 16.8324 4.02316L16.1753 4.33289ZM11.7932 5.55447L3.34247 14.0052C3.21617 14.1315 3.14828 14.3097 3.14828 14.4961V16.1645C3.14828 16.3509 3.21617 16.5291 3.34247 16.6554C3.46877 16.7817 3.64697 16.8496 3.83343 16.8496H5.50187C5.68833 16.8496 5.86653 16.7817 5.99283 16.6554L14.4435 8.20473L11.7932 5.55447ZM3.14828 15.4961V14.1654L4.48911 15.5062L3.14828 16.847V15.4961ZM6.17677 12.4684L12.7062 5.93897L14.0612 7.29398L7.53181 13.8234L6.17677 12.4684Z"
        fill={selected ? "white" : "#00000066"}
        fill-opacity="1"
      ></path>
    </svg>
  );
};

export default EditIcon;
