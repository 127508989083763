import Sidebar from "../appBar/appbar";
// import Navbar from "../sidebar";
import Courses from "./courses";


export default function Course(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Courses/>
      </div>
        </>
    )
}