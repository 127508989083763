import React from "react";

const OrderStatusIcon = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={selected ? "white" : "#00000066"}
        fillOpacity="1"
        d="M12,2C9.243,2,7,4.243,7,7c0,3.891,5,11,5,11s5-7.109,5-11 C17,4.243,14.757,2,12,2z M12,9.5c-1.381,0-2.5-1.119-2.5-2.5S10.619,4.5,12,4.5s2.5,1.119,2.5,2.5S13.381,9.5,12,9.5z"
      />
      <path
        fill={selected ? "white" : "#00000066"}
        fillOpacity="1"
        d="M20,9.5c0,0-2,2-3,2s-2-2-2-2l-1.588,4.763C15.951,13.919,16.464,14,17,14c1.381,0,2.5-1.119,2.5-2.5 S21.381,9.5,20,9.5z"
      />
    </svg>
  );
};

export default OrderStatusIcon
