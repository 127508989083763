import Sidebar from "../appBar/appbar";
// import Navbar from "../sidebar";
import Products from "./products";


export default function ProductsMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Products/>
      </div>
        </>
    )
}