import React from "react";

const Icon11 = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      xmlSpace="preserve"
    >
      <path
        d="M4.66667 14.7567H14V13.3513H4.66667V14.7567ZM4.66667 10.5405H19.3333V9.13509H4.66667V10.5405ZM4.66667 6.3243H19.3333V4.9189H4.66667V6.3243ZM0 24V2.26972C0 1.62324 0.205778 1.08356 0.617333 0.6507C1.028 0.2169 1.54 0 2.15333 0H21.8467C22.46 0 22.972 0.2169 23.3827 0.6507C23.7942 1.08356 24 1.62324 24 2.26972V17.4059C24 18.0524 23.7947 18.5925 23.384 19.0263C22.9724 19.4592 22.46 19.6756 21.8467 19.6756H4.10267L0 24ZM3.53333 18.2702H21.8467C22.0511 18.2702 22.2391 18.1802 22.4107 18.0004C22.5813 17.8195 22.6667 17.6214 22.6667 17.4059V2.26972C22.6667 2.05422 22.5813 1.85606 22.4107 1.67524C22.2391 1.49534 22.0511 1.4054 21.8467 1.4054H2.15333C1.94889 1.4054 1.76089 1.49534 1.58933 1.67524C1.41867 1.85606 1.33333 2.05422 1.33333 2.26972V20.5821L3.53333 18.2702Z"
        fill={selected ? "white" : "#00000066"}
        fill-opacity="1"
      ></path>
    </svg>
  );
};

export default Icon11;
