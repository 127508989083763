import { Suspense } from "react";
import Sidebar from "../appBar/appbar";
import FullPageProgress from "../../components/FullPageProgress";
const Conference = React.lazy(() => import("../../components/conference"));


export default function LiveConference(){
    return(
        <>
      <div style={{display:"flex",flex: 1}}>

      <Sidebar/>


<div style={{display:"flex",flex:5, justifyContent:"center",alignContent:"center"}}>

<Suspense fallback={<FullPageProgress />}>
              <Conference />
            </Suspense>


     
            </div>            
            
              </div>
        </>
    )
}