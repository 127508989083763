// import Navbar from "./sidebar";
import Announcement from "../dashboard/announcement";
import Sidebar from "../appBar/appbar";


export default function AnnouncementMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <Announcement/>
      </div>
        </>
    )
}