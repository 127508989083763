import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { setRoomData, setAuthToken } from '../live0/redux/actionTypes.js';
import fetch from 'node-fetch';
import Sidebar from "../../appBar/appbar";
import { Modal } from 'react-bootstrap';
import "./showAndCreate.css";
// import MyVerticallyCenteredModal from './join-modal';
import TokenModal from './fetchToken-Modal';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



// import 'bootstrap/dist/css/bootstrap.min.css';

export default function ShowAndCreate() {
  const [modalShow, setModalShow] = React.useState(false);
  const [currentRoomId, setCurrentRoomId] = useState(''); // State to track the current room ID
    const [rooms, setRooms] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showAllReporters, setShowAllReporters] = useState(false); // State for the dummy modal
    const [searchQuery, setSearchQuery] = useState(''); // State for search input
    const [newRoom, setNewRoom] = useState({
        name: '',
        template_id: '',
    });
    const dispatch = useDispatch();
    // const authToken = useSelector((state) => state.room.authToken);
  
    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await fetch('https://api.100ms.live/v2/rooms', {
                    headers: {
                        Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTk0MjQzMzEsImV4cCI6MTcyMDYzMzkzMSwianRpIjoiMGUzNDc2ODItZmNkMy00ZGQ3LWIyYWUtNjY5MTZmMzViZTM3IiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3MTk0MjQzMzEsImFjY2Vzc19rZXkiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODcifQ.xcgZg1wLF5wL6oSbZkInoOcK2FioRUKv6MDlQZP--GI'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                  

                }

                const data = await response.json();
                setRooms(data.data);
                console.log('data',data);
            } catch (error) {
                console.error('Error fetching rooms:', error);

            }
        };

        fetchRooms();
    }, []);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const [mainKey, subKey] = name.split('.');
            setNewRoom({ ...newRoom, [mainKey]: { ...newRoom[mainKey], [subKey]: value } });
        } else {
            setNewRoom({ ...newRoom, [name]: value });
        }
    };
    // const showSwal = () => {
    //   withReactContent(Swal).fire({
    //     title: <i>Input something</i>,
    //     input: 'text',
    //     inputValue,
    //     preConfirm: () => {
    //       setInputValue(Swal.getInput()?.value || '')
    //     },
    //   })
    // }
    const createRoom = async () => {
        try {
            const response = await fetch('https://api.100ms.live/v2/rooms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTk0MjQzMzEsImV4cCI6MTcyMDYzMzkzMSwianRpIjoiMGUzNDc2ODItZmNkMy00ZGQ3LWIyYWUtNjY5MTZmMzViZTM3IiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3MTk0MjQzMzEsImFjY2Vzc19rZXkiOiI2NjM2MjJhY2EzZjFjNGM2MGY0NjNjODcifQ.xcgZg1wLF5wL6oSbZkInoOcK2FioRUKv6MDlQZP--GI'
                },
                body: JSON.stringify(newRoom)
            });

            if (!response.ok) {

                throw new Error('Network response was not ok');
            }

            const createdRoom = await response.json();
            setRooms([...rooms, createdRoom]);
            closeModal();
        } catch (error) {
            console.error('Error creating room:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    // Dummy data for reporters (example)
    const reporters = [
        { reporterDetails: { username: 'reporter1' }, reason: 'Reason 1' },
        { reporterDetails: { username: 'reporter2' }, reason: 'Reason 2' }
    ];

    const filteredReporters = reporters.filter(reporter =>
        reporter.reporterDetails.username.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
        <div style={{ display: "flex", flex: 1 }}>
            <Sidebar />
            <div style={{ padding: '20px', width: '100%' }}>
                <h1>Available rooms</h1>
                <button onClick={openModal} style={{ marginBottom: '20px' , border:"1px solid blue", borderRadius:"10px", outline:"none" }}>Create Room</button>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                    {rooms.map(room => (
                        <li key={room.id} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                           {console.log(room)}
                            <div><strong>Name:</strong> {room.name}</div>
                            <div><strong>ID:</strong> {room.id}</div>
                            <div><strong>Description:</strong> {room.description}</div>
                            <div><strong>Created At:</strong> {new Date(room.created_at).toLocaleString()}</div>

                            <button style={{ marginTop: '10px' }} onClick={() => { setModalShow(true); setCurrentRoomId(room.id);}}>Join</button>
                        </li>
                    ))}
                </ul>
            </div>

            <Modal
                show={modalIsOpen}
                onHide={closeModal}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Room</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                       
                            <div style={{width:"100%",display:"flex"}}>
                                <span>Name</span>
                                <input className="create-formm" type="text" name="name" value={newRoom.name} onChange={handleInputChange} />
                            </div>
                     
                  
                        <div>
                            <label>
                                Template ID:
                                <input className="create-formm" type="text" name="template_id" value={newRoom.template_id} onChange={handleInputChange} />
                            </label>
                        </div>
                 
               
                 
                      
                        <button style={{marginBottom:'10'}} type="button" className="btn btn-primary" onClick={createRoom}>Create</button>
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAllReporters}
                onHide={() => setShowAllReporters(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="lime-redddddd">All Reporters</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-viggggg">
                    <div className="input-container-giggle">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                    {filteredReporters.map((reporter, idx) => (
                        <div key={idx} className="index-settled-container">
                            <div className="repo-container-full">
                                <span className="giggggggggggggggg">
                                    {reporter.reporterDetails.username}
                                </span>{" "}
                                <span className="limeeeeeeeeee giggggggggggggggg">
                                    {reporter.reason}
                                </span>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
            

            {/* //Join MODAL */}
            <TokenModal         roomId={currentRoomId}
 show={modalShow} onHide={() => setModalShow(false)} />

{/* <MyVerticallyCenteredModal
    show={modalShow}
    onHide={() => setModalShow(false)}
  /> */}

            
        </div>
    );
}
