import React from "react";

const Icon13 = ({ selected }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <path
        d="M21.486 18.1395H19.5643C19.4331 18.1395 19.3101 18.1981 19.2281 18.3014C19.0368 18.5386 18.8318 18.7675 18.6158 18.9851C17.7326 19.8877 16.6864 20.607 15.5352 21.1033C14.3424 21.6176 13.0604 21.8814 11.7656 21.8791C10.4563 21.8791 9.18794 21.6167 7.99613 21.1033C6.84485 20.607 5.79868 19.8877 4.91546 18.9851C4.03067 18.0856 3.32515 17.0194 2.83799 15.8456C2.33229 14.6289 2.07808 13.3368 2.07808 12C2.07808 10.6633 2.33503 9.37119 2.83799 8.15445C3.32456 6.97957 4.02434 5.92189 4.91546 5.01492C5.80659 4.10794 6.84259 3.39352 7.99613 2.89678C9.18794 2.38329 10.4563 2.12097 11.7656 2.12097C13.075 2.12097 14.3433 2.3805 15.5352 2.89678C16.6887 3.39352 17.7247 4.10794 18.6158 5.01492C18.8318 5.23538 19.034 5.46422 19.2281 5.69864C19.3101 5.80189 19.4359 5.8605 19.5643 5.8605H21.486C21.6582 5.8605 21.7648 5.66515 21.6691 5.51724C19.5725 2.19074 15.9042 -0.0111205 11.7356 4.22474e-05C5.18608 0.0167864 -0.0649962 5.44468 0.000608082 12.1228C0.0662123 18.6949 5.30909 24 11.7656 24C15.9233 24 19.5753 21.8009 21.6691 18.4828C21.7621 18.3349 21.6582 18.1395 21.486 18.1395ZM23.9161 11.8242L20.0372 8.69863C19.8924 8.58142 19.6819 8.68747 19.6819 8.87444V10.9954H11.0987C10.9784 10.9954 10.88 11.0958 10.88 11.2186V12.7814C10.88 12.9042 10.9784 13.0047 11.0987 13.0047H19.6819V15.1256C19.6819 15.3126 19.8951 15.4186 20.0372 15.3014L23.9161 12.1758C23.9422 12.155 23.9634 12.1283 23.9779 12.0978C23.9924 12.0673 24 12.0339 24 12C24 11.9661 23.9924 11.9327 23.9779 11.9022C23.9634 11.8718 23.9422 11.8451 23.9161 11.8242Z"
        fill={selected ? "white" : "#00000066"}
        fill-opacity="1"
      ></path>
    </svg>
  );
};

export default Icon13;
