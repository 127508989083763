import React from "react";

const Icon15 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="20"
    height="20"
    x="0"
    y="0"
    viewBox="0 0 60 60"
    style={{ enableBackground: "new 0 0 25 25" }}
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M58.519 58.599c-.56.54-1.45.53-2.01 0L30 33.96 3.491 58.599c-.56.53-1.45.54-2.01 0-.61-.56-.64-1.49-.09-2.1L25.9 30.14 2.361 8.27c-4.05-4.03 1.85-9.97 5.91-5.91L30 25.729 51.729 2.36c4.06-4.06 9.96 1.88 5.91 5.91L34.1 30.14l24.509 26.359c.55.61.52 1.54-.09 2.1z M24.487 30.191.649 55.828c-.917 1.015-.847 2.588.138 3.49.942.91 2.433.914 3.385.013L30 35.324l25.821 24.001c.48.454 1.094.68 1.703.68s1.209-.224 1.67-.669c1.003-.92 1.073-2.493.146-3.518L35.512 30.191l16.64-15.459a1 1 0 0 0-1.361-1.465l-17.372 16.14a1.004 1.004 0 0 0-.051 1.414L57.867 57.17a.491.491 0 0 1-.042.709.46.46 0 0 1-.635-.013l-26.509-24.64a.999.999 0 0 0-1.361 0L2.804 57.872a.462.462 0 0 1-.646-.011.484.484 0 0 1-.033-.682L26.634 30.82a1.002 1.002 0 0 0-.051-1.414L3.066 7.561C1.902 6.403 1.898 5.254 2.1 4.496c.301-1.129 1.26-2.092 2.387-2.395.757-.205 1.907-.203 3.051.94L29.267 26.41c.379.406 1.086.406 1.465 0L52.437 3.067c1.169-1.169 2.318-1.171 3.076-.966 1.127.303 2.086 1.265 2.387 2.395.202.759.198 1.907-.941 3.042L54.32 9.99a1 1 0 0 0 1.361 1.465l2.663-2.475c1.416-1.409 1.958-3.23 1.488-4.998C59.346 2.157 57.854.661 56.031.17c-1.765-.474-3.592.066-5.034 1.51L30 24.262 8.978 1.653C7.56.236 5.733-.305 3.969.169 2.146.66.654 2.156.168 3.981-.302 5.749.24 7.57 1.681 9.002l22.807 21.189z"
        fill="#808080a4"
        opacity="1"
        data-original="#000000"
        className=""
      ></path>
    </g>
  </svg>
);

export default Icon15;
