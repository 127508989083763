import Sidebar from "../appBar/appbar";
// import Navbar from "../sidebar";
// import StickyHeadTable from "./add-lessons";
import EditLessons from "./edit-lessons";


export default function EditLessonMain(){
    return(
        <>
      <div style={{display:"flex"}}>
      <Sidebar/>
        <EditLessons/>
      </div>
        </>
    )
}